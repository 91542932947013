import { faInfo, faLink, faList } from '@fortawesome/pro-solid-svg-icons';
import { useCountryTranslation } from 'brightsky-3';
import { decode } from 'html-entities';
import React, { useEffect } from 'react';
import LocateSupportBanner from '../locatesupport/LocateSupportBanner';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import useDocumentTitleContext from '../shared/hooks/useDocumentTitleContext';
import PagePanel from '../shared/PagePanel';
import TextBlock from '../shared/TextBlock';
import './css/privacypolicypage.less';

const PrivacyPolicyPage = () => {
    const c = useActiveCountry();
    const { ct, l } = useCountryTranslation(c);
    const { setDocumentTitle } = useDocumentTitleContext();

    const pageTitle = decode(ct('Web_Page_Title_PrivacyPolicy.Text'));

    const headPrivacy = decode(ct('Page_Web_PrivacyPolicy.Title'));
    const textPrivacy = decode(ct('Page_Web_PrivacyPolicy.Content'));

    useEffect(() => {
        setDocumentTitle('Privacy Policy: Bright Sky');
    }, [setDocumentTitle]);

    return (
        <div className="page-isitabuse">
            <LocateSupportBanner />
            <PagePanel background="#FFFFFF">
                <div className="page-title">
                    <a id="forms-of-abuse"></a>
                    <h3>{pageTitle}</h3>
                </div>
            </PagePanel>
            <PagePanel>
                <p style={{ marginLeft: '20px', marginRight: '20px' }} dangerouslySetInnerHTML={{ __html: textPrivacy }}></p>
            </PagePanel>
        </div>
    );
};

export default PrivacyPolicyPage;
