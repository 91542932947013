import React from 'react';
import { faMapMarker } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useConfigContext from '../shared/hooks/useConfigContext';

const MapMarker = ({ text }) => {
    const { theme } = useConfigContext();
    return (
        <div style={{ width: 26 }}>
            <div style={{ position: 'relative', textAlign: 'center', top: 22, fontSize: 12, fontWeight: 'bold', color: '#FFF' }}>{text}</div>
            <FontAwesomeIcon icon={faMapMarker} color={theme.secondary} style={{ fontSize: 36 }} />
        </div>
    );
};

export default MapMarker;
