import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconTag = ({ text, icon }) => {
    return (
        <div>
            <FontAwesomeIcon icon={icon} /> {text}
        </div>
    );
};

IconTag.propTypes = {
    icon: PropTypes.object,
    text: PropTypes.string,
};

export default IconTag;
