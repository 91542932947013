import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import './css/defaulttextbox.less';

const DefaultTextArea = ({ onChange, onBlur, value, icon, placeholder, id, name, className, disabled = false }) => {
    const { TextArea } = Input;
    return (
        <TextArea
            id={id}
            name={name}
            className={`default-textbox ${className ?? ''}`}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            disabled={disabled}
            suffix={icon}
        />
    );
};

DefaultTextArea.propTypes = {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    icon: PropTypes.node,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
};

export default DefaultTextArea;
