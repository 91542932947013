import React, { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import EducationResourcesPage from '../education/EducationResourcesPage';
import IsItAbusePage from '../isitabuse/IsItAbusePage';
import AbuseAdvicePage from '../abuseadvice/AbuseAdvicePage';
import OnlineSafetyPage from '../onlinesafety/OnlineSafetyPage';
import ConfigPage from '../home/ConfigPage';
import HomePage from '../home/HomePage';
import useConfigContext from '../shared/hooks/useConfigContext';
import TopNavigation from './TopNavigation';
import LocateSupportPage from '../locatesupport/LocateSupportPage';
import NationalHelplinesPage from '../locatesupport/NationalHelplinesPage';
import Footer from './Footer';
import PrivacyPolicyPage from '../privacy/PrivacyPolicyPage';
import AboutPage from '../about/AboutPage';
import FeedbackPage from '../feedback/FeedbackPage.js';
import SafetyGuidancePage from '../safety-guidance/SafetyGuidancePage.js';
import useDocumentTitleContext from '../shared/hooks/useDocumentTitleContext';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import { contentExists } from '../../util/functions';
import { useCountryTranslation } from 'brightsky-3';
import { decode } from 'html-entities';

const Routes = () => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);
    const history = useHistory();
    const { hasConfirmedConfig } = useConfigContext();
    const { documentTitle } = useDocumentTitleContext();
    const [showTitleAlert, setShowTitleAlert] = useState(false);

    useEffect(() => {
        history.listen(() => {
            document.getElementById('headerPrimaryNav').focus();
        });
    }, []);

    useEffect(() => {
        setShowTitleAlert(true);
        setTimeout(() => {
            setShowTitleAlert(false);
        }, 500);
    }, [documentTitle]);

    return (
        <>
            {showTitleAlert && (
                <div style={{ position: 'absolute', top: -200 }} aria-live="polite" aria-atomic="true" role="alert">
                    {`Navigated to page ${documentTitle}`}
                </div>
            )}
            {hasConfirmedConfig && <TopNavigation />}

            <main>
                <Route exact path="/" component={ConfigPage} />
                {hasConfirmedConfig && <>
                    <Route exact path="/:lang" component={HomePage} />
                    <Route exact path="/:lang/home" component={HomePage} />
                    <Route path="/:lang/home/:consId" component={HomePage} />
                    <Route path="/:lang/is-it-abuse" component={IsItAbusePage} />
                    <Route path="/:lang/abuse-advice" component={AbuseAdvicePage} />
                    <Route path="/:lang/education-resources" component={EducationResourcesPage} />
                    <Route path="/:lang/online-safety-guide" component={OnlineSafetyPage} />
                    <Route path="/:lang/locate-support/:location" component={LocateSupportPage} />
                    <Route path="/:lang/national-helplines" component={NationalHelplinesPage} />
                    <Route path="/:lang/feedback" component={FeedbackPage} />
                    <Route path="/:lang/privacy" component={PrivacyPolicyPage} />
                    <Route path="/:lang/about" component={AboutPage} />
                    {contentExists(c, "Page_Web_SafetyGuidance.Title", decode(ct('Page_Web_SafetyGuidance.Title'))) && (
                      <Route path="/:lang/safety-guidance" component={SafetyGuidancePage} />
                    )}
                </>}
            </main>

            {hasConfirmedConfig && <Footer />}
        </>
    );
};

export default Routes;
