import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { decode } from 'html-entities';
import { StorageKeys, useCountryTranslation } from 'brightsky-3';
import PagePanel from '../shared/PagePanel';
import BoundButton from '../shared/BoundButton';
import BoundingBox from '../shared/BoundingBox';
import TextBlock from '../shared/TextBlock';
import Quicklinks from './Quicklinks';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import useContentContext from '../shared/hooks/useContentContext';
import './css/homepage.less';
import useConfigContext from '../shared/hooks/useConfigContext';

const HomePrimaryPanel = () => {
    const c = useActiveCountry();
    const { ct, l } = useCountryTranslation(c);
    const history = useHistory();
    const { language } = useConfigContext();
    const { content, getContent } = useContentContext();
    const [amIAtRiskQuestionnaire, setAmIAtRiskQuestionnaire] = useState();
    const [formsOfAbuseGuide, setFormsOfAbuseGuide] = useState();

    useEffect(() => {
        const hasQContent = content != null && content[StorageKeys.QuestionnaireContentKey] != null;
        if (hasQContent) {
            const qContent = getContent(StorageKeys.QuestionnaireContentKey, 'Questionnaire_AmIAtRisk');
            setAmIAtRiskQuestionnaire(qContent);
        }
        const hasGContent = content != null && content[StorageKeys.GuideKey] != null;
        if (hasGContent) {
            const formsOfAbuseContent = getContent(StorageKeys.GuideKey, 'Guide_FormsOfAbuse');
            setFormsOfAbuseGuide(formsOfAbuseContent);
        }
    }, [setAmIAtRiskQuestionnaire, setFormsOfAbuseGuide, language]);

    const headIsItAbuse = decode(ct('Nav_Home_IsItAbuse.Text'));
    const headAbuseForms = decode(ct('Nav_Home_FormsOfAbuse.Text'));
    const textMoreOn = decode(ct('Web_Text_MoreOn.Text'));

    const headAmIAtRisk = decode(amIAtRiskQuestionnaire?.Title);
    const textAmIAtRisk = decode(amIAtRiskQuestionnaire?.Description);

    const hasGuides = formsOfAbuseGuide != null && formsOfAbuseGuide.Guides != null;
    const guides = [];
    if (hasGuides) {
        for (var i = 0; i < 3; i++) {
            const gi = formsOfAbuseGuide.Guides[i];
            const tLower = gi?.Title?.toLowerCase();
            guides.push(<TextBlock key={i.toString()} title={decode(gi.Title)} content={decode(gi.Content)} />);
        }
    }

    return (
        <PagePanel id="skip-to" background="#FFFFFF">
            <div className="home-content">
                <div className="left home-left">
                    <h3>{headIsItAbuse}</h3>
                    <TextBlock icon={faUser} title={headAmIAtRisk} content={textAmIAtRisk}></TextBlock>
                    <BoundButton
                        className="home-page-buttons"
                        binding="Web_Button_AmIAtRisk.Text"
                        type="primary"
                        tooltipBinding="Web_Tooltip_AmIAtRisk.Text"
                        onClick={() => {
                            window.scrollTo(0, 0);
                            history.push(`/${l}/is-it-abuse`);
                        }}
                    />
                    <Quicklinks />
                </div>
                <div className="right">
                    <BoundingBox full>
                        <h3>{headAbuseForms}</h3>
                        {guides}
                        <BoundButton
                            className="home-page-buttons"
                            binding="Web_Button_AllFormsOfAbuse.Text"
                            type="primary"
                            tooltipBinding="Web_Tooltip_AllFormsOfAbuse.Text"
                            onClick={() => {
                                window.scrollTo(0, 0);
                                history.push(`/${l}/education-resources`);
                            }}
                        />
                    </BoundingBox>
                </div>
            </div>
        </PagePanel>
    );
};

export default HomePrimaryPanel;
