import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import useConfig from "../../../config/hooks/useConfig";
import useActiveCountry from "../hooks/useActiveCountry";
import { getCountrySubdomain, getLangFromUrl } from "../../../util/functions";
import http from "brightsky-3/services/httpRequestService";

export const AppSettingsContext = createContext();

export const AppSettingsContextProvider = ({ children }) => {
    const [appSettings, setAppSettings] = useState();
    const [isAppSettingsLoading, setAppSettingsLoading] = useState(false);    

    return (
        <AppSettingsContext.Provider value={{ appSettings, setAppSettings, isAppSettingsLoading, setAppSettingsLoading }}>
            {children}
        </AppSettingsContext.Provider>
    );
};

AppSettingsContextProvider.propTypes = {
    children: PropTypes.node,
};
