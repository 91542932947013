import { faHandsHelping, faHeart, faHeartBroken, faSiren, faWalking } from '@fortawesome/pro-solid-svg-icons';
import { StorageKeys, useCountryTranslation, useIcon } from 'brightsky-3';
import { decode } from 'html-entities';
import React, { useEffect, useState } from 'react';
import LocateSupportBanner from '../locatesupport/LocateSupportBanner';
import BoundGuideAccordion from '../shared/BoundGuideAccordion';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import useContentContext from '../shared/hooks/useContentContext';
import PagePanel from '../shared/PagePanel';
import TextBlock from '../shared/TextBlock';
import BackToTop from '../shared/BackToTop';
import './css/abuseadvicepage.less';
import useConfigContext from '../shared/hooks/useConfigContext';
import useDocumentTitleContext from '../shared/hooks/useDocumentTitleContext';
import useGuidesContext from '../shared/hooks/useGuidesContext';
import useWebAnalyticsContext from '../shared/hooks/useWebAnalyticsContext';
import { buildGuideName, GuideKeys } from '../shared/Guides';
import { Events, Origins, Templates } from 'brightsky-3/constants/Logging';
import useAppSettingsContext from '../shared/hooks/useAppSettingsContext';

const GUIDE_HASHMAP = {
    "#leaving": GuideKeys.LeavingKey,
    "#how-can-i-help": GuideKeys.HowCanIHelpKey,
    "#types-of-support": GuideKeys.TypesOfSupportKey,
    "#sexual-consent": GuideKeys.SexualConsentKey,
    "#stalking-harassment": GuideKeys.StalkingHarassmentKey,
    "#additional": GuideKeys.AdditionalKey,
};

const AbuseAdvicePage = () => {
    const c = useActiveCountry();
    const { appSettings } = useAppSettingsContext();
    const { l, ct } = useCountryTranslation(c);
    const { language } = useConfigContext();
    const { content, getContent } = useContentContext();
    const { setDocumentTitle } = useDocumentTitleContext();
    const { getGuide } = useGuidesContext();
    const { logEvent } = useWebAnalyticsContext();
    const [g1, setG1] = useState();
    const [g2, setG2] = useState();
    const [g3, setG3] = useState();
    const [g4, setG4] = useState();
    const [g5, setG5] = useState();
    const [g6, setG6] = useState();

    const path = window.location.hash;
    const pageHead = decode(ct('Nav_Home_AbuseAdvice.Text'));

    const subhead1 = decode(g1?.Title);
    const subtext1 = decode(g1?.Description);
    const link1 = `${l}/abuse-advice#leaving`;

    const subhead2 = decode(g2?.Title);
    const subtext2 = decode(g2?.Description);
    const link2 = `${l}/abuse-advice#how-can-i-help`;

    const subhead3 = decode(g3?.Title);
    const subtext3 = decode(g3?.Description);
    const link3 = `${l}/abuse-advice#types-of-support`;

    const subhead4 = decode(g4?.Title);
    const subtext4 = decode(g4?.Description);
    const link4 = `${l}/abuse-advice#sexual-consent`;

    const subhead5 = decode(g5?.Title);
    const subtext5 = decode(g5?.Description);
    const link5 = `${l}/abuse-advice#stalking-harassment`;

    const subhead6 = decode(g6?.Title);
    const subtext6 = decode(g6?.Description);
    const link6 = `${l}/abuse-advice#additional`;
    const icon6 = useIcon(ct("Nav_Home_Additional_IconName.Text"));

    const setCurrentGuide = (guideKey) => {
        if (!guideKey || guideKey === '') return;
        const fullGuideName = buildGuideName(guideKey, c);
        const guide = getGuide(0, fullGuideName);
        return guide;
    };

    useEffect(() => {
		const guideKey = GUIDE_HASHMAP[path];
        const guide = setCurrentGuide(guideKey);

        if (guide && guide.guideName && Events?.GuideOpen) {
        	logEvent(Events.GuideOpen, Origins.Guide, Templates.guide.open(guide.guideName));
		}
        
    }, [path]);

    useEffect(() => {
        setDocumentTitle('Abuse Information and Support: Bright Sky');
    }, [setDocumentTitle]);

    useEffect(() => {
        const hasGContent = content != null && content[StorageKeys.GuideKey] != null;
        if (hasGContent) {
            setG1(getContent(StorageKeys.GuideKey, 'Guide_Leaving'));
            setG2(getContent(StorageKeys.GuideKey, 'Guide_HowCanIHelp'));
            setG3(getContent(StorageKeys.GuideKey, 'Guide_TypesOfSupport'));
            setG4(getContent(StorageKeys.GuideKey, 'Guide_SexualConsent'));
            setG5(getContent(StorageKeys.GuideKey, 'Guide_StalkingHarassment'));
            setG6(getContent(StorageKeys.GuideKey, 'Guide_Additional'));
        }
    }, [setG1, setG2, setG3, setG4, setG5, setG6, language]);

    return (
        <div className="page-isitabuse">
            <LocateSupportBanner />
            <PagePanel background="#FFFFFF">
                <div className="page-title" style={{ marginTop: 12, marginBottom: 12 }}>
                    <h3>{pageHead}</h3>
                </div>
                <div className="page-as-grid" style={{ display: 'flex' }}>
                    <TextBlock guideLink icon={faHeartBroken} title={subhead1} content={subtext1} link={link1} buttonTitle={subhead1} />
                    <TextBlock guideLink icon={faHandsHelping} title={subhead2} content={subtext2} link={link2} buttonTitle={subhead2} />
                </div>
                <div className="page-as-grid" style={{ display: 'flex' }}>
                    <TextBlock guideLink icon={faSiren} title={subhead3} content={subtext3} link={link3} buttonTitle={subhead3} />
                    <TextBlock guideLink icon={faHeart} title={subhead4} content={subtext4} link={link4} buttonTitle={subhead4} />
                </div>
                <div className="page-as-grid" style={{ display: 'flex' }}>
                    <TextBlock guideLink icon={faWalking} title={subhead5} content={subtext5} link={link5} buttonTitle={subhead5} />
                    {appSettings.additionalGuideEnabled && subtext6 && (
                        <TextBlock guideLink icon={icon6} title={subhead6} content={subtext6} link={link6} buttonTitle={subhead6} />
                    )}
                </div>
            </PagePanel>
            <PagePanel>
                <div className="guide-accordion-box no-images">
                    <a className="anchor" id="leaving" />
                    <h3>{subhead1}</h3>
                    <p dangerouslySetInnerHTML={{ __html: subtext1 }} />
                    <BoundGuideAccordion binding="Guide_Leaving" />
                    <br />
                    <BackToTop />
                </div>
                <div className="guide-accordion-box no-images">
                    <a className="anchor" id="how-can-i-help" />
                    <h3>{subhead2}</h3>
                    <p dangerouslySetInnerHTML={{ __html: subtext2 }} />
                    <BoundGuideAccordion binding="Guide_HowCanIHelp" />
                    <br />
                    <BackToTop />
                </div>
                <div className="guide-accordion-box">
                    <a className="anchor" id="types-of-support" />
                    <h3>{subhead3}</h3>
                    <p dangerouslySetInnerHTML={{ __html: subtext3 }} />
                    <BoundGuideAccordion binding="Guide_TypesOfSupport" />
                    <br />
                    <BackToTop />
                </div>
                <div className="guide-accordion-box">
                    <a className="anchor" id="sexual-consent" />
                    <h3>{subhead4}</h3>
                    <p dangerouslySetInnerHTML={{ __html: subtext4 }} />
                    <BoundGuideAccordion binding="Guide_SexualConsent" />
                    <br />
                    <BackToTop />
                </div>
                <div className="guide-accordion-box">
                    <a className="anchor" id="stalking-harassment" />
                    <h3>{subhead5}</h3>
                    <p dangerouslySetInnerHTML={{ __html: subtext5 }} />
                    <BoundGuideAccordion binding="Guide_StalkingHarassment" />
                    <br />
                    <BackToTop />
                </div>
                {appSettings.additionalGuideEnabled && <div className="guide-accordion-box">
                    <a className="anchor" id="additional" />
                    <h3>{subhead6}</h3>
                    <p dangerouslySetInnerHTML={{ __html: subtext6 }} />
                    <BoundGuideAccordion binding="Guide_Additional" />
                    <br />
                    <BackToTop />
                </div>}
            </PagePanel>
        </div>
    );
};

export default AbuseAdvicePage;
