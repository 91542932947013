import React from 'react';
import { decode } from 'html-entities';
import { useCountryTranslation } from 'brightsky-3';
import PagePanel from '../shared/PagePanel';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import BoundGuideAccordion from '../shared/BoundGuideAccordion';

const HomeGuidePanel = () => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);

    const headLeavingAbuse = decode(ct('Nav_AbuseAdvice_Leaving.Text'));

    return (
        <PagePanel background="#FFFFFF">
            <div className="no-images">
                <h3>{headLeavingAbuse}</h3>
                <BoundGuideAccordion binding="Guide_Leaving" />
            </div>
        </PagePanel>
    );
};

export default HomeGuidePanel;
