import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Layout from 'antd/lib/layout/layout';
import { AppSettingsContextProvider } from './features/shared/contexts/AppSettingsContext';
import { ContentContextProvider } from './features/shared/contexts/ContentContext';
import { ConfigContextProvider } from './features/shared/contexts/ConfigContext';
import { LocationSearchContextProvider } from './features/locatesupport/contexts/LocationSearchContext';
import { QuestionnaireContextProvider } from './features/isitabuse/contexts/QuestionnaireContext';
import { WebAnalyticsContextProvider } from './features/shared/contexts/WebAnalyticsContext';
import { DocumentTitleContextProvider } from './features/shared/contexts/DocumentTitleContext';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { GuidesContextProvider } from './features/shared/contexts/GuidesContext';
import 'antd/dist/antd.css';
import './theme/vars.less';
import { appInsightsPlugin } from './util/appInsights';
import FocusVisible from './features/shared/FocusVisible';
import './App.css';
import { Loader } from './Loader';

require('./assets/images/favicon.ico');


function App() {
    

    return (
        <Layout className="layout">
            <FocusVisible className="js-focus-visible focus-visible">
                <BrowserRouter>

					<ConfigContextProvider>
						<ContentContextProvider>
							<AppSettingsContextProvider>
                                <WebAnalyticsContextProvider>
                                    <GuidesContextProvider>
                                        <LocationSearchContextProvider>
                                            <DocumentTitleContextProvider>
                                                <QuestionnaireContextProvider>
                                                    <Loader />
                                                </QuestionnaireContextProvider>
                                            </DocumentTitleContextProvider>
                                        </LocationSearchContextProvider>
                                    </GuidesContextProvider>
                                </WebAnalyticsContextProvider>
							</AppSettingsContextProvider>
						</ContentContextProvider>
					</ConfigContextProvider>   
					    
                </BrowserRouter>
            </FocusVisible>
        </Layout>
    );
}



export default withAITracking(appInsightsPlugin, App);
