import React, { useEffect, useState } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { HiMenu } from 'react-icons/hi';
import { Affix, Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { decode } from 'html-entities';
import { faArrowRight, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { useCountryTranslation } from 'brightsky-3';
import { Events, Origins, Templates } from 'brightsky-3/constants/Logging';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import BoundButton from '../shared/BoundButton';
import useAppSettingsContext from '../shared/hooks/useAppSettingsContext';
import useWebAnalyticsContext from '../shared/hooks/useWebAnalyticsContext';
import './css/navigation.less';

const { Header } = Layout;

function TopNavigation () {
    const c = useActiveCountry();
    const { ct, l } = useCountryTranslation(c);
    const { logEvent } = useWebAnalyticsContext();
    const { appSettings } = useAppSettingsContext();
    const { pathname } = useLocation();

    const [pathIndex, setPathIndex] = useState(null);

    const onCall999 = () => {
        const call999Link = decode(ct('Link_CallEmergencyServices.Text'));
        if (Events?.AppCalledEmergency) {
            logEvent(Events.AppCalledEmergency, Origins.App, Templates.app.calledEmergency(call999Link));
        }
        window.open(call999Link);
    };

    const onExit = () => {
        window.location.href = appSettings.exitUrl;
    };
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    const skipToAnchor = window.location + '#skip-to';

    const [toggleMenu, setToggleMenu] = useState(false);

    useEffect(() => {
        const pathRegex = /^\/.+?\/(?<path>.+)$/;
        const pathFromRegex = pathname.match(pathRegex);

        if (pathFromRegex && pathFromRegex.groups && pathFromRegex.groups.path) {
            setPathIndex(pathFromRegex.groups.path);
        } else {
            setPathIndex(null);
        }
    }, [pathname]);

    const handleKeyDownClose = event => {
        if (event.key === 'Enter') {
            setToggleMenu(false);
        }
    };

    const handleKeyDownOpen = event => {
        if (event.key === 'Enter') {
            setToggleMenu(true);
        }
    };

    const Navmenu = () => (
        <Menu className="top-menu navbar-links_container" id="primary-navbar" theme="dark" mode="horizontal" selectedKeys={[pathIndex]} tabIndex={-1}>
            <Menu.Item key="is-it-abuse" className="bs-menu-item" style={{ backgroundColor: 'transparent' }}>
                <Link to={`/${l}/is-it-abuse`} onClick={scrollToTop} className="hover-underline">
                    {decode(ct('Nav_Home_IsItAbuse.Text'))}
                </Link>
            </Menu.Item>

            <Menu.Item key="education-resources" className="bs-menu-item" style={{ backgroundColor: 'transparent' }}>
                <Link to={`/${l}/education-resources`} onClick={scrollToTop} className="hover-underline">
                    {decode(ct('Nav_Home_FormsOfAbuse.Text'))}
                </Link>
            </Menu.Item>
            <Menu.Item key="abuse-advice" className="bs-menu-item" style={{ backgroundColor: 'transparent' }}>
                <Link to={`/${l}/abuse-advice`} onClick={scrollToTop} className="hover-underline">
                    {decode(ct('Nav_Home_AbuseAdvice.Text'))}
                </Link>
            </Menu.Item>
            <Menu.Item key="online-safety-guide " className="bs-menu-item" style={{ backgroundColor: 'transparent' }}>
                <Link to={`/${l}/online-safety-guide`} onClick={scrollToTop} className="hover-underline">
                    {decode(ct('Nav_Home_Web_OnlineSafety.Text'))}
                </Link>
            </Menu.Item>
            <Menu.Item key="5" className="bs-menu-item addtl-menu main-phone-exit" style={{ backgroundColor: '#c8c8c8' }}>
                <BoundButton
                    icon={faPhone}
                    onClick={onCall999}
                    binding="Nav_Tab_Call.Text"
                    tooltipBinding="Web_Tooltip_Call999.Text"
                    className="phone-button"
                />
                <BoundButton
                    iconRight={faArrowRight}
                    onClick={onExit}
                    binding="Text_Exit.Text"
                    tooltipBinding="Web_Tooltip_ExitSite.Text"
                    className="exit-button"
                    style={{ backgroundColor: '#66008a' }}
                />
            </Menu.Item>
        </Menu>
    );

    const Navmenusecondary = () => (
        <Menu theme="dark" className="secondary-menu">
            <Menu.Item key="1" className="menu-abuse-outline">
                <Link
                    className="link-color"
                    to={`/${l}/is-it-abuse`}
                    onClick={() => {
                        {
                            setToggleMenu(false);
                            window.top.scrollTo(0, 100);
                        }
                    }}
                >
                    {decode(ct('Nav_Home_IsItAbuse.Text'))}
                </Link>
            </Menu.Item>

            <Menu.Item key="4" className="menu-outline">
                <Link
                    to={`/${l}/education-resources`}
                    onClick={() => {
                        {
                            setToggleMenu(false);
                            document.body.scrollTo(0, 100);
                        }
                    }}
                >
                    {decode(ct('Nav_Home_FormsOfAbuse.Text'))}
                </Link>
            </Menu.Item>
            <Menu.Item key="2" className="menu-outline">
                <Link
                    to={`/${l}/abuse-advice`}
                    onClick={() => {
                        {
                            setToggleMenu(false);
                            document.body.scrollTo(0, 100);
                        }
                    }}
                >
                    {decode(ct('Nav_Home_AbuseAdvice.Text'))}
                </Link>
            </Menu.Item>
            <Menu.Item key="3" className="menu-outline bottom">
                <Link
                    to={`/${l}/online-safety-guide`}
                    onClick={() => {
                        {
                            setToggleMenu(false);
                            document.body.scrollTo(0, 100);
                        }
                    }}
                >
                    {decode(ct('Nav_Home_Web_OnlineSafety.Text'))}
                </Link>
            </Menu.Item>
        </Menu>
    );

    return (
        <Affix>
            <Header className="header" id="header">
                <nav
                    style={{ position: 'fixed', width: '100%', top: '0' }}
                    id="headerPrimaryNav"
                    tabIndex={-1}
                    className="menu"
                    aria-label="Header Navigation"
                >
                    <div className="navbar-menu">
                        {toggleMenu ? (
                            <RiCloseLine
                                id="hamburger-toggle-open"
                                tabIndex={0}
                                aria-expanded="false"
                                color="fff"
                                size={27}
                                onClick={() => setToggleMenu(false)}
                                onKeyDown={handleKeyDownClose}
                            />
                        ) : (
                            <HiMenu
                                id="hamburger-toggle-close"
                                tabIndex={0}
                                aria-expanded="false"
                                color="fff"
                                size={27}
                                onClick={() => setToggleMenu(true)}
                                onKeyDown={handleKeyDownOpen}
                            />
                        )}
                        {toggleMenu && (
                            <div className="navbar-menu_container scale-up-center">
                                <div id="nav" className="navbar-menu_container-links">
                                    <Navmenusecondary />
                                </div>
                            </div>
                        )}
                    </div>
                    <nav className="nav-icon">
                        <Link onClick={scrollToTop} to={`/${l}/home`}>
                            <img className="home-nav" viewBox="0, 0, 24, 24" width="125vmin" src={require('../../assets/images/bs-logo-white.svg')} />
                        </Link>
                    </nav>
                    <a className="skip-link" href={skipToAnchor}>
                        Skip to content
                    </a>
                    <div className="addtl-menu phone-exit" id="phone-exit">
                        <BoundButton className="phone-button" icon={faPhone} onClick={onCall999} />
                        <BoundButton iconRight={faArrowRight} onClick={onExit} className="exit-button" />
                    </div>
                    <div>
                        <nav>
                            <Navmenu className="main-nav-menu" />
                        </nav>
                    </div>
                </nav>
            </Header>
        </Affix>
    );
}

export default TopNavigation;
