import React, { useEffect, useState } from 'react';
import { decode } from 'html-entities';
import { useCountryTranslation } from 'brightsky-3';
import PagePanel from '../shared/PagePanel';
import LocateSupportSearch from '../locatesupport/LocateSupportSearch';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import defaultImage from '../../assets/images/bg-succulent-wide-t.png';
import './css/homepage.less';
import useConfig from '../../config/hooks/useConfig';
import useAppSettingsContext from '../shared/hooks/useAppSettingsContext';

const HomeHero = () => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);
    const { appSettings } = useAppSettingsContext();
    const [heroImage, setHeroImage] = useState();
    const config = useConfig();

    useEffect(() => {
        const settingsImage = appSettings?.webHeroImage;
        if (settingsImage != null && settingsImage?.length > 0) {
            setHeroImage(config.assetUrl + settingsImage[0]);
        } else {
            setHeroImage(defaultImage);
        }
    }, [appSettings, setHeroImage]);

    const headAlone = decode(ct('Title_YouAreNotAlone.Text'));
    const headTagline = decode(ct('Web_Header_Home_DomesticAbuseFault.Text'));

    return (
        <PagePanel background={`url(${heroImage})`}>
            <div className="home-banner">
                <h2>{headTagline}</h2>
                <h1>{headAlone}</h1>
                <br />
                <LocateSupportSearch />
            </div>
        </PagePanel>
    );
};

export default HomeHero;
