import React from 'react';
import PropTypes from 'prop-types';
import './css/defaulttextbox.less';
import { Modal } from 'antd';
import { useCountryTranslation } from 'brightsky-3';
import useActiveCountry from '../shared/hooks/useActiveCountry';

const DefaultModal = ({ visible, onOk, onCancel, title, children, footer, width = 600 }) => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);

    return (
        <Modal width={width} title={title} visible={visible} onOk={onOk} onCancel={onCancel} footer={footer} cancelText={ct('Label_Cancel.Text')}>
            {children}
        </Modal>
    );
};

DefaultModal.propTypes = {
    title: PropTypes.string,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    children: PropTypes.node,
    footer: PropTypes.any,
    visible: PropTypes.bool,
    width: PropTypes.number,
};

export default DefaultModal;
