import { useCountryTranslation } from 'brightsky-3';
import { decode } from 'html-entities';
import React, { useEffect } from 'react';
import LocateSupportBanner from '../locatesupport/LocateSupportBanner';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import useDocumentTitleContext from '../shared/hooks/useDocumentTitleContext';
import PagePanel from '../shared/PagePanel';
import './css/aboutpage.less';

const AboutPage = () => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);
    const { setDocumentTitle } = useDocumentTitleContext();
    const hasWebContent = ct('Page_About.Title') !== 'Page_About.Title';

    const pageTitle = hasWebContent ? decode(ct('Page_About.Title')) : decode(ct('Web_Page_Title_About.Text'));
    const pageContent = hasWebContent ? decode(ct('Page_About.Content')) : decode(ct('Page_About.Content'));

    useEffect(() => {
        setDocumentTitle('About Bright Sky Information: Bright Sky');
    }, [setDocumentTitle]);

    return (
        <div className="page-isitabuse">
            <LocateSupportBanner />
            <PagePanel id="skip-to" background="#FFFFFF">
                <div className="page-title">
                    <a id="about-title" />
                    <h3>{pageTitle}</h3>
                </div>
            </PagePanel>
            <PagePanel>
                <div className="page-title">
                    <a id="about-content" />
                </div>
                <p style={{ marginLeft: '20px', marginRight: '20px' }} dangerouslySetInnerHTML={{ __html: pageContent }} />
            </PagePanel>
        </div>
    );
};

export default AboutPage;
