import React, { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { useCountryTranslation } from 'brightsky-3';
import { Events, Origins } from 'brightsky-3/constants/Logging';
import useActiveCountry from '../hooks/useActiveCountry';
import http from 'brightsky-3/services/httpRequestService';
import useConfig from '../../../config/hooks/useConfig';
import { getConsumerIdFromUrl } from '../../../util/functions';
import { getCountrySubdomain } from '../../../util/functions';
import { api } from '../../../util/api';


export const WebAnalyticsContext = createContext();

export const WebAnalyticsContextProvider = ({ children }) => {
    const { l } = useCountryTranslation();
    const [consumerId, setConsumerId] = useState();
	const c = useActiveCountry();
    const env = useConfig();

    const registerConsumer = (urlCountry, urlConsumer) => {

        if (urlConsumer) {
			setConsumerId(urlConsumer);
			if(!urlCountry)throw "Impossible webanalytics state: Consumer present and no country detected.";
            
            if (Events?.AppOpen) {
                logEventManual(urlCountry, urlConsumer, Events.AppOpen, Origins.App, { EnterWebsite: urlCountry });
            }
        } else {
            let countryOrVf = urlCountry ? urlCountry : 'VF';
            const consumerData = {
                countryCode: countryOrVf,
                deviceType: 'Web',
                applicationVersion: 'Web',
                coverMode: 'NA',
                languageCode: l,
            };

            api.post(`Analytics/Register`, consumerData).then((data)=>{

				setConsumerId(data.Id);


                if (Events?.AppOpen) {
                    logEventManual(countryOrVf, data.Id, Events.AppOpen, Origins.App, { EnterWebsite: countryOrVf });
                }

			}).catch((err)=>{
				console.error('Unable to register webanalytics: ', err);
			});
	
		}
    };

	const logEventManual = (country, consumer, event, origin, log) =>{
            const stringLog = log ? JSON.stringify(log) : log;
            const eventLog = {
                consumerId: consumer,
                type: event.type,
                subType: event.subType,
                origin,
                market: country,
                log: stringLog,
            };

            http.post(
                `${env.apiUrl}Analytics/Event`,
                eventLog,
                e => { }, // We want to fire & forget logs to avoid a performance impact
                err => {
                    console.warn(err);
                }
            );
	}

    const logEvent = (event, origin, log) => {
        if (consumerId) {
            const stringLog = log ? JSON.stringify(log) : log;
            const eventLog = {
                consumerId,
                type: event.type,
                subType: event.subType,
                origin,
                market: c,
                log: stringLog,
            };

            http.post(
                `${env.apiUrl}Analytics/Event`,
                eventLog,
                e => { }, // We want to fire & forget logs to avoid a performance impact
                err => {
                    console.warn(err);
                }
            );
        }
    };

    return (<WebAnalyticsContext.Provider value={{ consumerId, registerConsumer, logEvent }}>
			{children}
		</WebAnalyticsContext.Provider>);
};

WebAnalyticsContextProvider.propTypes = {
    children: PropTypes.node,
};
