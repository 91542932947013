import { useEffect, useState } from 'react';
import useConfig from '../../../config/hooks/useConfig';
import useAppSettingsContext from './useAppSettingsContext';
import defaultImage from '../../../assets/images/bg-succulent-wide-t.png';

const useHeroImage = () => {
    const { appSettings } = useAppSettingsContext();
    const [heroImage, setHeroImage] = useState();
    const config = useConfig();

    useEffect(() => {
        const settingsImage = appSettings?.webHeroImage;
        if (settingsImage != null && settingsImage?.length > 0) {
            setHeroImage(config.assetUrl + settingsImage[0]);
        } else {
            setHeroImage(defaultImage);
        }
    }, [appSettings, setHeroImage]);

    return heroImage;
};

export default useHeroImage;
