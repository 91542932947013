import localConfig from '../config.local.json';
import devConfig from '../config.dev.json';
import qaConfig from '../config.qa.json';
import prodConfig from '../config.prod.json';

const isEnv = prefix => {
    const hostname = window.location.hostname;
    return hostname.indexOf(prefix) > -1;
};

const useConfig = () => {
    const isLocalhost = isEnv('localhost');
    const isLocal = isEnv('bslocal') || isEnv('bs-local');
    const isDev = isEnv('dev');
    const isQa = isEnv('qa');
    const isProd = isEnv('bright-sky.org');

    if (isLocalhost) {
        alert("Redirecting to http://dev.bslocal.com:3000, if you haven't already, see the Readme.md for how to configure support for this url.");
        window.location.href = 'http://dev.bslocal.com:3000';
    }

    if (isLocal) {
        return localConfig;
    } else if (isDev) {
        return devConfig;
    } else if (isQa) {
        return qaConfig;
    } else if (isProd) {
        return prodConfig;
    }
};

export default useConfig;
