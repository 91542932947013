import * as Icons from '@fortawesome/pro-solid-svg-icons';

export const isEmptyObject = objectToTest => {
    return JSON.stringify(objectToTest) === '{}';
};

export const getCountrySubdomain = () => {
    const domainParts = window.location.host.split('.');
    const subdomain = domainParts.length === 3 ? domainParts[0] : null;

    const hasCountryInUrl =
        subdomain != null &&
        domainParts.length === 3 &&
        subdomain.toLowerCase() !== 'www' &&
        subdomain.toLowerCase() !== 'dev' &&
        subdomain.toLowerCase() !== 'qa';
    let sCountry = hasCountryInUrl ? subdomain : null;

    if (subdomain != null && hasCountryInUrl)
        sCountry = subdomain?.indexOf('-') !== -1 ? subdomain.split('-')[1].toUpperCase() : subdomain.toUpperCase();

    return sCountry;
};

export const hasCountryInDomain = () => {
    const domainParts = window.location.host.split('.');
    const subdomain = domainParts.length === 3 ? domainParts[0] : null;

    const hasCountryInUrl =
        subdomain != null &&
        domainParts.length === 3 &&
        subdomain.toLowerCase() !== 'www' &&
        subdomain.toLowerCase() !== 'dev' &&
        subdomain.toLowerCase() !== 'qa';

    return hasCountryInUrl;
};

export const getLangFromUrl = () => {
    let urlLang = window.location.pathname.split('/')[1];
    return urlLang ? urlLang : null;
};

export const getConsumerIdFromUrl = () => {
    let tok = window.location.pathname.split('/');
    return tok[2] === "home" ? tok[3] : null;
};

export const resolveIcon = (name, assetUri) => {
    const customIconKey = '*UseAsset*';

    if (name && name !== customIconKey) {
        const fullIconName = `fa${name}`;
        const icon = Icons[fullIconName];
        return icon;
    }

    if (name && name === customIconKey && assetUri) {
        return {
            isCustom: true,
            uri: assetUri,
        };
    }

    return null;
};

export const contentExists = (country, contentName, content) => {
    var boundContentName = country + "_" + contentName;
    return content.localeCompare(boundContentName) !== 0 && content.localeCompare("NA") !== 0;
};
