import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { useCountryTranslation } from 'brightsky-3';
import './css/boundbutton.less';
import useActiveCountry from './hooks/useActiveCountry';
import { decode } from 'html-entities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BoundLinkButton = ({ ariaLabel, binding, onClick, disabled, icon, tooltipBinding }) => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);
    const iconElement = icon != null ? <FontAwesomeIcon style={{ marginRight: 12 }} icon={icon} /> : null;
    const tooltipText = decode(ct(tooltipBinding));

    // TODO: Tooltips have been disabled until there is time/budget to
    //       create the translations needed to populate their values.

    return (
        // <Tooltip title={tooltipText}>
        <Button type="text" icon={iconElement} aria-label={ariaLabel} className="bound-button link-button" disabled={disabled} onClick={onClick}>
            {decode(ct(binding))}
        </Button>
        // </Tooltip>
    );
};

BoundLinkButton.propTypes = {
    ariaLabel: PropTypes.string,
    binding: PropTypes.string.isRequired,
    tooltipBinding: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.any,
    disabled: PropTypes.bool,
};

export default BoundLinkButton;
