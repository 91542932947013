import { StorageKeys, useCountryTranslation } from 'brightsky-3';
import { Events, Origins, Templates } from 'brightsky-3/constants/Logging';
import { faPlus, faMinus } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { decode } from 'html-entities';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import PagePanel from '../shared/PagePanel';
import BoundButton from '../shared/BoundButton';
import BoundLinkButton from '../shared/BoundLinkButton';
import useContentContext from '../shared/hooks/useContentContext';
import useQuestionnaireContext from './hooks/useQuestionnaireContext';
import PossibleAnswers from './PossibleAnswers';
import useConfigContext from '../shared/hooks/useConfigContext';
import './css/boundquestionnaire.less';
import ButtonGroup from 'antd/lib/button/button-group';
import ShowMoreShowLess from '../shared/ShowMoreShowLess';
import useWebAnalyticsContext from '../shared/hooks/useWebAnalyticsContext';

const BoundQuestionnaire = ({ binding }) => {
    const { getQuestionnaire } = useQuestionnaireContext();
    const { logEvent } = useWebAnalyticsContext();
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);
    const { language } = useConfigContext();
    const { content, getContent } = useContentContext();
    const { currentQuestionIndex, reset, startQuestionnaire, isComplete, correctAnswerCount, answers, scoreQuestionnaire } =
        useQuestionnaireContext();
    const [currentQuestionnaire, setCurrentQuestionnaire] = useState();
    const [showLearnMore, setShowLearnMore] = useState('');
    const complete = isComplete();
	const [isCompleteFlag, setCompleteFlag] = useState(false);
    let correctPercent = 0;
    let closestResult = '';
    const hasResults = currentQuestionnaire?.Results != null && currentQuestionnaire?.Results?.length > 0;

    if (complete && hasResults) {
        const results = currentQuestionnaire.Results;
        correctPercent = Math.round((correctAnswerCount / answers.length) * 100);
        scoreQuestionnaire();
        closestResult =
            results != null &&
            results.length > 0 &&
            results.reduce((result1, result2) =>
                Math.abs(result2.ResultThreshold - correctPercent) < Math.abs(result1.ResultThreshold - correctPercent) ? result2 : result1
            );

		if(!isCompleteFlag){
			//only runs once
			setCompleteFlag(true);

            if (Events?.QuestionnaireCompleteEvent) {
                logEvent(Events.QuestionnaireCompleteEvent, Origins.Questionnaire, Templates.questionnaire.complete(currentQuestionnaire));
            }
			
		}
    }

    useEffect(() => {
        const hasQContent = content != null && content[StorageKeys.QuestionnaireContentKey] != null;
        if (hasQContent) {
            setCurrentQuestionnaire(getContent(StorageKeys.QuestionnaireContentKey, binding));
        }
    }, [setCurrentQuestionnaire, language]);

    const understandContents = useRef(null);

    useEffect(() => {
        if (currentQuestionnaire != null) {
            const buildQuestionnaireName = (name, country) => `${country}_${name}`;
            const fullQuestionnaireName = buildQuestionnaireName(binding, c);
            const q = getQuestionnaire(0, fullQuestionnaireName);
            startQuestionnaire(q);
            if (Events?.QuestionnaireStartEvent) {
                logEvent(Events.QuestionnaireStartEvent, Origins.Questionnaire, Templates.questionnaire.start(fullQuestionnaireName));
            }
        }
    }, [currentQuestionnaire]);

    const onShowMoreClick = () => {
        setShowLearnMore(!showLearnMore);
    };

    useEffect(() => {
        if (showLearnMore) {
            understandContents.current.focus();
        }
    }, [showLearnMore]);

    const bind = t => decode(t);

    const questions =
        currentQuestionnaire != null
            ? currentQuestionnaire.Questions.map((q, i) => {
                  const display = currentQuestionIndex >= i ? 'block' : 'none';
                  const isCurrentQuestion = currentQuestionIndex === i ? 'current' : '';

                  const hasAnswerNotifications =
                      q.CorrectNotification != null &&
                      q.CorrectNotification != '' &&
                      q.IncorrectNotification != null &&
                      q.IncorrectNotification != '';
                  const hasAnswered = answers[i] != null;

                  const hasLearnMoreInfo = q.LearnMore != null && q.LearnMore != '';
                  let learnMoreContent = <div ref={understandContents} dangerouslySetInnerHTML={{ __html: bind(q.LearnMore) }}></div>;
                  learnMoreContent = hasLearnMoreInfo ? learnMoreContent : null;
                  let responseContent =
                      answers[i] === q.Answer ? (
                          <div className="q-notification" dangerouslySetInnerHTML={{ __html: bind(q.CorrectNotification) }}></div>
                      ) : (
                          <div className="q-notification" dangerouslySetInnerHTML={{ __html: bind(q.IncorrectNotification) }}></div>
                      );
                  responseContent = hasAnswerNotifications && hasAnswered ? responseContent : null;
                  return (
                      <div key={i.toString()} className={`question ${isCurrentQuestion}`} style={{ display: display }}>
                          <div className="question-full">
                              <p className="question-text" dangerouslySetInnerHTML={{ __html: `${bind(q.Question)}` }}></p>{' '}
                              {hasLearnMoreInfo && (
                                  <ShowMoreShowLess
                                      binding1={'Text_Label_UnderstandThisQuestion.Text'}
                                      binding2={'Text_Label_UnderstandThisQuestion.Text'}
                                      limit={0}
                                  >
                                      <div className="learn-more-content">{learnMoreContent}</div>
                                  </ShowMoreShowLess>
                              )}
                              {showLearnMore && hasLearnMoreInfo && learnMoreContent}
                          </div>
                          {responseContent}
                          <div tabIndex="-1" role="contentinfo" id="buttonGroup" style={{ color: '#FFF', userSelect: 'none' }}>
                              Button Group: Yes, No, Not sure
                          </div>
                          <ButtonGroup className="answers" role="group" aria-label="Button group">
                              <PossibleAnswers role="button" qIndex={i} possibleAnswers={currentQuestionnaire.PossibleAnswers} answers={answers} />
                          </ButtonGroup>
                      </div>
                  );
              })
            : [];

    const handleStartOver = () => {
        setShowLearnMore();
        reset();
    };

    const showResults = complete && currentQuestionnaire?.Results != null && hasResults && closestResult != null;

    return (
        <PagePanel background="#FFFFFF">
            <div className="questionnaire">
                <h3>{bind(currentQuestionnaire?.Title)}</h3>
                {questions}
                {showResults && (
                    <div className="results">
                        <h3>{decode(ct('Title_Summary.Text'))}</h3>
                        <p dangerouslySetInnerHTML={{ __html: bind(closestResult.WebResultContent) }}></p>
                    </div>
                )}
                <div className="actions">{complete && <BoundButton binding="Button_StartOver.Text" onClick={handleStartOver} />}</div>
            </div>
        </PagePanel>
    );
};

BoundQuestionnaire.propTypes = {
    binding: PropTypes.string,
};

export default BoundQuestionnaire;
