import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { decode } from 'html-entities';

const DefaultSelect = ({ id, onChange, value, items = [], defaultValue, loading = false, size = 'medium', width, allowClear = true }) => {
    const { Option } = Select;
    let fixedWidth = 120;
    switch (size) {
        case 'small':
            fixedWidth = 120;
            break;
        case 'medium':
            fixedWidth = 200;
            break;
        case 'large':
            fixedWidth = 300;
            break;
    }
    fixedWidth = width == null ? fixedWidth : width;

    const mappedItems = items.map((v, i) => {
        return (
            <Option key={i.toString()} value={v.value}>
                {decode(v.label || '')}
            </Option>
        );
    });

    return (
        <Select
            id={id}
            className="default-select"
            onChange={onChange}
            defaultValue={defaultValue}
            value={value}
            style={{ width: fixedWidth, marginBottom: 12 }}
            allowClear={allowClear}
            loading={loading}
        >
            {mappedItems}
        </Select>
    );
};

DefaultSelect.propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func,
    items: PropTypes.array,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    items: PropTypes.array,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    allowClear: PropTypes.bool,
};

export default DefaultSelect;
