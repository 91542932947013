import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import Routes from './features/navigation/Routes';

import useLoader from './features/shared/hooks/useLoader';
import { getConsumerIdFromUrl, getCountrySubdomain, getLangFromUrl } from './util/functions';
import useConfigContext from './features/shared/hooks/useConfigContext';
import { useHistory } from 'react-router';
import { StorageKeys } from 'brightsky-3';
import useWebAnalyticsContext from './features/shared/hooks/useWebAnalyticsContext';

export const Loader = function() {
	
	const loader = useLoader();
	const history = useHistory();

	const config = useConfigContext();

	const web_anal = useWebAnalyticsContext();

	const [isLoaded, setLoaded] = useState(false);

	const init = async (callback)=>{

		let start_dt = Date.now();

		const urlCountry = getCountrySubdomain();
		const urlLang = getLangFromUrl();

		let content_store = await loader.fetchCountries({});

		if (urlCountry) {
			const url_consumer = getConsumerIdFromUrl();

			//if at least the country is selected
			config.setCountry(urlCountry);

			loader.fetchThemes().then((themes)=>{
				loader.enableTheme(themes, urlCountry);
			});
			
			const app_settings = await loader.fetchAppSettings(urlCountry);

			await loader.fetchCountrySpecificContent(content_store, urlCountry)

			const valid_langs = loader.getLanguagesByCountry(content_store[StorageKeys.CountryStore], urlCountry);
			config.setLanguages(valid_langs);
			
			if (urlLang) {

				const is_url_lang_valid = valid_langs.filter((x)=>x.value===urlLang).length > 0;

				if(is_url_lang_valid){

					//if country and language are specified then bypass the config page
					config.setLanguage(urlLang);
					await loader.setTranslations(urlCountry, urlLang);
					config.setConfirmedConfig(true);

					web_anal.registerConsumer(urlCountry, url_consumer);

					callback();
					

				}else{

					//if country and language are specified, but language is invalid, then pull up the site with the countries default language selected
					
					config.setLanguage(app_settings.defaultLanguageCode);
					await loader.setTranslations(urlCountry, app_settings.defaultLanguageCode);
					config.setConfirmedConfig(true);
					history.push(`/${app_settings.defaultLanguageCode}/home`);

					web_anal.registerConsumer(urlCountry, url_consumer);

					callback();

				}

			}else{

				//if country and no language, then pull up the site with the countries default language selected
				config.setLanguage(app_settings.defaultLanguageCode);
				await loader.setTranslations(urlCountry, app_settings.defaultLanguageCode);
				config.setConfirmedConfig(true);
				history.push(`/${app_settings.defaultLanguageCode}/home`);

				web_anal.registerConsumer(urlCountry, url_consumer);

				callback();

			}

			

		}else if(urlLang){

			//if a country is not specified while a language is specified, redirect them to root and have them pick a country and a language.
			loader.fetchThemes();
			history.push("/");
			loader.setNullTranslation();

			web_anal.registerConsumer();

			callback();

		}else{

			//if no country nor language is specified, then open up the config page and have them choose a country and a language
			loader.fetchThemes();
			loader.setNullTranslation();

			web_anal.registerConsumer();

			callback();
		}
	}

	useEffect(() => {
		(async () => {
			await init(()=>{
				setLoaded(true);
			});
		})();
	}, []);

	if(isLoaded){
		const urlLang = getLangFromUrl();
		if(urlLang!==config.language){
			console.warn("New language detected in URL path except there was no page reload.");
		}
	}

	return (<>
		{isLoaded && <Routes />}
		{!isLoaded && <div className="flex-center">
			<Spin style={{ marginTop: 50, height: '100vh' }} size="large" />
		</div>}
	</>);
}