import React, { useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import useTagsContent from '../hooks/useTagsContent';
import { StorageKeys, useCountryTranslation } from 'brightsky-3';
import { Events, Origins, Templates } from 'brightsky-3/constants/Logging';
import useConfig from '../../../config/hooks/useConfig';
import http from 'brightsky-3/services/httpRequestService';
import useContentContext from '../../shared/hooks/useContentContext';
import useActiveCountry from '../../shared/hooks/useActiveCountry';
import { getDistance } from 'geolib';
import useWebAnalyticsContext from '../../shared/hooks/useWebAnalyticsContext';

const initialState = {
    locationSearch: null,
};

export const LocationSearchContext = createContext(initialState);

export const LocationSearchContextProvider = ({ children }) => {
    const Config = useConfig();
    const c = useActiveCountry();
    const { logEvent } = useWebAnalyticsContext();
    const { l } = useCountryTranslation();
    const { content } = useContentContext();
    const { tagsData } = useTagsContent();
    const [tags, setTags] = useState();
    const [tagFilters, setTagFilters] = useState([]);
    const [locationSearch, setLocationSearch] = useState();
    const [coordinates, setCoordinates] = useState();
    const [mapRegion, setMapRegion] = useState();
    const [locationResults, setLocationResults] = useState();
    const [selectedLocation, setSelectedLocation] = useState();
    const [loading, setLoading] = useState(false);

    const getCountryName = () => {
        const cacheStore = content[StorageKeys.CountryStore];
        const cc = `${c}`;
        const filteredCountry = cacheStore.filter(c => c.Code.iv == cc);
        return filteredCountry.length > 0 ? filteredCountry[0].Name.en : null;
    };
    const countryName = content != null && content[StorageKeys.CountryStore] != null ? getCountryName() : null;

    useEffect(() => {
        setTags(tagsData);
    }, [tagsData]);

    const getMapRegion = (locationData, coords) => {
        let minX;
        let maxX;
        let minY;
        let maxY;

        minX = coords.lat;
        maxX = coords.lat;
        minY = coords.lng;
        maxY = coords.lng;

        locationData.map(point => {
            minX = Math.min(minX, point.location.lat || 0);
            maxX = Math.max(maxX, point.location.lat || 0);
            minY = Math.min(minY, point.location.lng || 0);
            maxY = Math.max(maxY, point.location.lng || 0);
        });

        const midX = (minX + maxX) / 2;
        const midY = (minY + maxY) / 2;
        const deltaX = maxX - minX;
        const deltaY = maxY - minY;

        const region = {
            latitude: midX,
            longitude: midY,
            latitudeDelta: deltaX + 0.05,
            longitudeDelta: deltaY + 0.05,
        };

        setMapRegion(region);
    };

    const getTag = tagId => {
        const tagMatches = tags?.filter(t => t.Id === tagId);
        return tagMatches != null && tagMatches.length > 0
            ? {
                  id: tagMatches[0].Id,
                  title: tagMatches[0].Title[l],
                  icon: tagMatches[0].Icon.iv,
                  color: tagMatches[0].ColorCode.iv,
                  borderColor: tagMatches[0].BorderColorCode.iv,
                  type: tagMatches[0].Type.iv,
              }
            : null;
    };

    const mapResult = (location, startingLocation) => {
        const distance =
            location?.Location?.iv != null && startingLocation
                ? (
                      getDistance(startingLocation, {
                          longitude: location?.Location?.iv?.Longitude,
                          latitude: location?.Location?.iv?.Latitude,
                      }) / 1000
                  ).toFixed(2)
                : 0;
        return {
            name: location.Name != null ? location.Name[l] : '',
            tags: location.Tags?.iv?.map(tagId => getTag(tagId)),
            phone: location.PrimaryPhoneNumber,
            phoneNumbers: location.PhoneNumbers?.iv?.map(p => p.Number),
            email: location.PrimaryEmailAddress,
            emailAddresses: location.EmailAddresses?.iv?.map(e => e.Email),
            address: location.Address != null ? location.Address[l] : '',
            hours: location.Hours != null ? location.Hours[l] : '',
            website: location.Website?.iv,
            description: location.Description != null ? location.Description[l] : '',
            distance,
            location: {
                lat: location.Location ? location?.Location?.iv?.Latitude : null,
                lng: location.Location ? location?.Location?.iv?.Longitude : null,
            },
            displayOnMap: location.DisplayOnMap?.iv,
            isNationalHelpLine: location.IsNationalHelpLine?.iv,
            lastVerified: location.LastVerified?.iv,
            order: location.Order?.iv == null ? -1 : location.Order?.iv,
        };
    };

    const compareNumbers = (a, b) => {
        const n1 = a.order != null && a.order >= 0 ? a.order : 100000;
        const n2 = b.order != null && b.order >= 0 ? b.order : 100000;
        return n1 - n2;
    };

    const getNationalHelplines = (currentFilters, onComplete) => {
        setLoading(true);
        const searchFilters = !currentFilters ? tagFilters : currentFilters;
        http.get(`${Config.apiUrl}Content/Locations/Helplines?country=${c}&tags=${searchFilters.join(',')}`, result => {
            let locationData = [];
            try {
                locationData = result.data
                    .map(location => {
                        const mappedResult = mapResult(location, null);
                        return mappedResult;
                    })
                    .sort(compareNumbers);
                if (onComplete) onComplete(locationData);
            } catch (ex) {
                console.warn('Error loading helplines', ex);
            }
            setLocationResults(locationData);
            setLoading(false);
        });
    };

    const getLocationResults = (geolocation, onComplete, currentFilters) => {
        setLoading(true);
        const currentTagFilters = currentFilters || tagFilters;
        const locationSearchRequest = {
            tags: currentTagFilters,
            country: c,
            latitude: geolocation.lat,
            longitude: geolocation.lng,
        };

        http.post(
            `${Config.apiUrl}Content/Locations`,
            locationSearchRequest,
            result => {
                const startingLocation = {
                    longitude: geolocation.lng,
                    latitude: geolocation.lat,
                };
                const locationData = result.data.map(location => {
                    const mappedResult = mapResult(location, startingLocation);
                    return mappedResult;
                });
                setLocationResults(locationData);
                getMapRegion(
                    locationData.filter(l2 => l2.location.lat != null && l2.location.lng != null && !l2.isNationalHelpLine),
                    geolocation
                );
                setLoading(false);
                if (onComplete) {
                    onComplete(locationData);
                }
            },
            err => {
                console.warn('Error searching locations: ', JSON.stringify(locationSearchRequest), err);
            }
        );
    };

    const runLocationNameSearch = (search, onComplete, currentFilters) => {
        setLoading(true);
        let calcSearch = search;
        if (search.toUpperCase().startsWith('JE4')) {
            calcSearch = `${search}, Jersey, Channel Islands`;
        } else if (search && search?.indexOf(`, ${countryName}`) === -1) {
            calcSearch = `${search}, ${countryName}`;
        }
        if (search) {
            const googleMapsApi = `https://maps.googleapis.com/maps/api/geocode/json?address=${calcSearch}&key=${Config.googleMapsKey}`;
            http.get(
                googleMapsApi,
                mapsResult => {
                    const firstResult = mapsResult.data.results.length > 0 ? mapsResult.data.results[0] : null;
                    const geolocation = firstResult ? firstResult.geometry.location : null;
                    setCoordinates(geolocation);
                    getLocationResults(geolocation, onComplete, currentFilters);
                },
                err => {
                    console.warn('Maps API Failure: ', err);
                }
            );
        } else {
            getNationalHelplines(currentFilters);
        }
    };

    const runSearch = (search, onComplete, currentFilters) => {
        const filterCount = currentFilters ? currentFilters.length : tagFilters.length;
        runLocationNameSearch(search, onComplete, currentFilters);
        if (Events?.LocationSearchEvent) {
            logEvent(Events.LocationSearchEvent, Origins.LocationSearch, Templates.location.search(search, filterCount));
        }
    };

    const addTagFilter = tagId => {
        const newTagFilters = [...tagFilters];
        newTagFilters.push(tagId);
        setTagFilters(newTagFilters);
    };

    const removeTagFilter = tagId => {
        const newTagFilters = [...tagFilters];
        setTagFilters(newTagFilters.filter(t => t !== tagId));
    };

    return (
        <LocationSearchContext.Provider
            value={{
                runSearch,
                locationSearch,
                mapRegion,
                setMapRegion,
                coordinates,
                setLocationSearch,
                loading,
                setLoading,
                locationResults,
                tags,
                tagFilters,
                setTagFilters,
                addTagFilter,
                removeTagFilter,
                selectedLocation,
                setSelectedLocation,
                getNationalHelplines,
            }}
        >
            {children}
        </LocationSearchContext.Provider>
    );
};

LocationSearchContextProvider.propTypes = {
    children: PropTypes.node,
};
