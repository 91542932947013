export const StorageKeys = {
    ResourceKey: "ResourceStore",
};

// USAGE: Adding storage keys here will automatically cache
//        the content on app startup
//        The "name" property should match that of the endpoint.
export const ContentStorageKeys = [
    { key: StorageKeys.ResourceKey, name: "Resources" }
];
