import { useContext } from 'react';
import { AppSettingsContext } from '../contexts/AppSettingsContext';
import useConfig from '../../../config/hooks/useConfig';

const useAppSettingsContext = () => {
    const context = useContext(AppSettingsContext);

    if (!context) {
        throw new Error('useAppSettings must be used within a ConfigContextProvider');
    }

    return context;
};

export default useAppSettingsContext;
