import { useContext } from 'react';
import { ContentContext } from '../contexts/ContentContext';

const useContentContext = () => {
    const context = useContext(ContentContext);
    if (!context) {
        throw new Error('useContentContext must be used within a ContentContextProvider');
    }
    return context;
};

export default useContentContext;
