import { useContext } from 'react';
import { WebAnalyticsContext } from '../contexts/WebAnalyticsContext';

const useWebAnalyticsContext = () => {
    const context = useContext(WebAnalyticsContext);
    if (!context) {
        throw new Error('useWebAnalyticsContext must be used within a WebAnalyticsContextProvider');
    }
    return context;
};

export default useWebAnalyticsContext;
