import { useContext } from 'react';
import { GuidesContext } from '../contexts/GuidesContext';

const useGuidesContext = () => {
    const context = useContext(GuidesContext);
    if (!context) {
        throw new Error('useGuidesContext must be used within a GuidesContextProvider');
    }
    return context;
};

export default useGuidesContext;
