import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DefaultModal from '../shared/DefaultModal';
import { useCountryTranslation } from 'brightsky-3';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import { Checkbox, Form } from 'antd';
import useTagsContent from './hooks/useTagsContent';
import BoxTag from './BoxTag';
import IconTag from './IconTag';
import { resolveIcon } from '../../util/functions';
import useLocationSearchContext from './hooks/useLocationSearchContext';
import { decode } from "html-entities";

const TagFilters = ({ onUpdated, onClose, visible, filters = [] }) => {
    const c = useActiveCountry();
    const { ct, l } = useCountryTranslation(c);
    const { tagsData } = useTagsContent();
    const [form] = Form.useForm();

    const compare = (a, b) => {
        const titleA = a?.Title[l].toUpperCase();
        const titleB = b?.Title[l].toUpperCase();

        let comparison = 0;
        if (titleA > titleB) {
            comparison = 1;
        } else if (titleA < titleB) {
            comparison = -1;
        }
        return comparison;
    };

    const filteredTags = tagsData.filter(tc => tc.SupportedCountries.iv.some(sc => sc?.Data?.Code.toString() == c));
    const supportTags = filteredTags?.filter(t => t.Type.iv === 'Type of Support').sort(compare);
    const locationTags = filteredTags?.filter(t => t.Type.iv === 'Location').sort(compare);

    // Setting the initial field values on load
    let fieldsValue = {};
    if (filteredTags != null && filteredTags?.length > 0) {
        for (var index in filters) {
            fieldsValue[filters[index]] = true;
        }
    }
    form.setFieldsValue(fieldsValue);

    const handleFilter = () => {
        const formFields = form.getFieldsValue();
        let selectedTags = [];

        for (var key of Object.keys(formFields)) {
            const val = formFields[key];
            if (val === true) {
                selectedTags.push(key);
            }
        }

        onUpdated(selectedTags);
        onClose();
    };

    return (
        <DefaultModal visible={visible} title={decode(ct('Text_FilterResults.Text'))} onCancel={onClose} onOk={handleFilter}>
            <Form form={form} layout="vertical">
                <div className="filter-title"></div>
                <div className="filter-list">
                    <div>
                        <h4>{decode(ct('Label_TypesOfSupport.Text'))}</h4>
                        {supportTags.map((t, i) => {
                            return (
                                <Form.Item name={t.Id} valuePropName="checked">
                                    <Checkbox key={i.toString()}>
                                        <BoxTag key={i.toString()} text={t.Title[l]} color={t.ColorCode?.iv} borderColor={t.BorderColorCode?.iv} />
                                    </Checkbox>
                                </Form.Item>
                            );
                        })}
                    </div>
                    <br />
                    <div>
                        <h4>{decode(ct('Label_LocationFilters.Text'))}</h4>
                        {locationTags.map((t, i) => {
                            return (
                                <Form.Item name={t.Id} valuePropName="checked">
                                    <Checkbox key={i.toString()}>
                                        <IconTag key={i} icon={resolveIcon(t.Icon?.iv)} text={t.Title[l]} />
                                    </Checkbox>
                                </Form.Item>
                            );
                        })}
                    </div>
                </div>
            </Form>
        </DefaultModal>
    );
};

TagFilters.propTypes = {
    onClose: PropTypes.func,
    onUpdated: PropTypes.func,
    visible: PropTypes.bool,
    filters: PropTypes.array,
};

export default TagFilters;
