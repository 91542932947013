import React, { useState, createContext } from 'react';
import { PropTypes } from 'prop-types';


export const ConfigContext = createContext(null);

export const ConfigContextProvider = ({ children }) => {
    const [country, setCountry] = useState();
    const [language, setLanguage] = useState();
    const [languages, setLanguages] = useState([]);
    const [themes, setThemes] = useState(false);
    const [theme, setTheme] = useState();
    const [hasConfirmedConfig, setConfirmedConfig] = useState(false);
    const [loading, setLoading] = useState(false);

    return (
        <ConfigContext.Provider
            value={{
                country,
                setCountry,
                language,
                setLanguage,
                languages,
				setLanguages,
                hasConfirmedConfig,
                setConfirmedConfig,
                themes,
				setThemes,
                theme,
				setTheme,
                loading,
				setLoading
            }}
        >
            {children}
        </ConfigContext.Provider>
    );
};

ConfigContextProvider.propTypes = {
    children: PropTypes.node,
};