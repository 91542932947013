import React from 'react';
import PropTypes from 'prop-types';
import './css/boundingbox.less';

const BoundingBox = ({ children, full = false }) => {
    const width = full ? '100%' : '50%';
    return (
        <div style={{ width: width }} className="bounding-box">
            {children}
        </div>
    );
};

BoundingBox.propTypes = {
    full: PropTypes.bool,
    children: PropTypes.node,
};

export default BoundingBox;
