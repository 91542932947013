import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import './css/defaultdatepicker.less';
import useActiveCountry from './hooks/useActiveCountry';
import { useCountryTranslation } from 'brightsky-3';

const DefaultDatePicker = ({ onChange, value, placeholder, defaultValue, disabled = false }) => {
    const c = useActiveCountry();
    const { l } = useCountryTranslation(c);
    const formatDate = c === 'US' ? 'MM-DD-YYYY' : 'DD-MM-YYYY';
    return (
        <DatePicker
            format={formatDate}
            locale={l}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            defaultValue={defaultValue}
        />
    );
};

DefaultDatePicker.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
};

export default DefaultDatePicker;
