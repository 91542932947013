import useConfig from "../config/hooks/useConfig"

const config = useConfig();


const get = async (path, data)=>{
	let response = await fetch(`${config.apiUrl}${path}`, {
		method: "GET",
		body: JSON.stringify(data),
	});

	return response;
};

const post = async (path, data)=>{
	let response = await fetch(`${config.apiUrl}${path}`, {
		method: "POST",
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data),
	}).catch((err)=>{
		throw err;
	});

	if(response && response.ok){
		return await response.json();
	}else{
		throw response ? `${response.status} ${response.statusText}` : "400 Network Error";
	}
};

export const api = {
	get,
	post,
};