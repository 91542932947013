import React, { useEffect } from 'react';
import HomeHero from './HomeHero';
import HomePrimaryPanel from './HomePrimaryPanel';
import OnlineSafetyPanel from './OnlineSafetyPanel';
import HomeGuidePanel from './HomeGuidePanel';
import './css/homepage.less';
import useDocumentTitleContext from '../shared/hooks/useDocumentTitleContext';

const HomePage = () => {
    const { setDocumentTitle } = useDocumentTitleContext();

    useEffect(() => {
        setDocumentTitle('Bright Sky: You are not alone: practical support and information on how to respond to domestic abuse.');
    }, [setDocumentTitle]);

    return (
        <div className="home">
            <HomeHero />
            <HomePrimaryPanel />
            <OnlineSafetyPanel />
            <HomeGuidePanel />
        </div>
    );
};

export default HomePage;
