import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { useCountryTranslation } from 'brightsky-3';
import useActiveCountry from '../hooks/useActiveCountry';
import { getCountrySubdomain } from '../../../util/functions';


export const ContentContext = createContext();

export const ContentContextProvider = ({ children }) => {
    const [currentContent, setCurrentContent] = useState();
    const [loaded, setLoaded] = useState();
    const c = useActiveCountry();
    
	const sCountry = getCountrySubdomain();
    const calcCountry = sCountry == null ? c : sCountry;

    const { bind, l, n } = useCountryTranslation(calcCountry);

    const getContent = (key, binding) => {
        const selected = getRawContent(key);
        const b = bind(binding);
        return selected[l][n][b];
    };

    const getRawContent = (key) => {
        const selected = currentContent[key];
        if (selected == null) {
            throw `ContentContext: No content found matching ${key} binding`;
        }

        return selected;
    };

    const hasContentSet = key => {
        return currentContent != null && currentContent[key];
    };

    return (
        <ContentContext.Provider value={{ hasContentSet, content: currentContent, setContent: setCurrentContent, getContent, getRawContent, loaded, setLoaded }}>
            {children}
        </ContentContext.Provider>
    );
};

ContentContextProvider.propTypes = {
    children: PropTypes.node,
};
