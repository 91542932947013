import { StorageKeys, useCountryTranslation } from 'brightsky-3';
import { decode } from 'html-entities';
import React, { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import LocateSupportBanner from '../locatesupport/LocateSupportBanner';
import BoundButton from '../shared/BoundButton';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import useContentContext from '../shared/hooks/useContentContext';
import PagePanel from '../shared/PagePanel';
import TextBlock from '../shared/TextBlock';
import BoundQuestionnaire from './BoundQuestionnaire';
import JournalForm from './JournalForm';
import useConfigContext from '../shared/hooks/useConfigContext';
import useQuestionnaireContext from './hooks/useQuestionnaireContext';
import { resolveIcon } from '../../util/functions';
import useDocumentTitleContext from '../shared/hooks/useDocumentTitleContext';
import './css/isitabuse.less';

const IsItAbusePage = () => {
    const { setDocumentTitle } = useDocumentTitleContext();
    const c = useActiveCountry();
    const { l, ct } = useCountryTranslation(c);
    const { language } = useConfigContext();
    const { content, getContent } = useContentContext();
    const { reset } = useQuestionnaireContext();
    const [showJournal, setShowJournal] = useState(false);
    const [showQuestionnaire, setShowQuestionnaire] = useState(false);
    const [questionnaire, setQuestionnaire] = useState();
    const [q1, setQ1] = useState();
    const [q2, setQ2] = useState();
    const [q3, setQ3] = useState();

    const pageTitle = decode(ct('Nav_Home_IsItAbuse.Text'));
    const headJournal = decode(ct('Page_Journal.Title'));
    const textJournal = decode(ct('Page_Journal.Content'));
    const link1 = `/${l}/is-it-abuse#journal`;

    useEffect(() => {
        setDocumentTitle('Is it abuse? Recognize abuse: Bright Sky');
    }, [setDocumentTitle]);

    useEffect(() => {
        const hasQContent = content != null && content[StorageKeys.QuestionnaireContentKey] != null;
        if (hasQContent) {
            setQ1(getContent(StorageKeys.QuestionnaireContentKey, 'Questionnaire_AmIAtRisk'));
            setQ2(getContent(StorageKeys.QuestionnaireContentKey, 'Questionnaire_FriendFamilyAtRisk'));
            setQ3(getContent(StorageKeys.QuestionnaireContentKey, 'Questionnaire_DispellingMyths'));
        }
    }, [setQ1, setQ2, setQ3, language]);

    const handleJournalClick = () => {
        setShowQuestionnaire(false);
        setShowJournal(!showJournal);
    };

    const QuestionnaireButton = ({ binding, text }) => {
        const onClick = () => {
            setQuestionnaire(binding);
            setShowQuestionnaire(true);
            setShowJournal(false);
            reset();
        };

        return (
            <HashLink tabIndex={-1} smooth to={link1} className="pad-left">
                <BoundButton text={text} type="primary" onClick={onClick} />
            </HashLink>
        );
    };

    const hasIcons = q1?.ListIcon && q2?.ListIcon && q3?.ListIcon;

    return (
        <div className="page-isitabuse">
            <LocateSupportBanner />
            <PagePanel background="#FFFFFF">
                <div className="page-title">
                    <h3>{pageTitle}</h3>
                </div>
                {hasIcons && (
                    <div className="flex-element main">
                        <div className="flex-element col-third" style={{ flexDirection: 'column' }}>
                            <div className="flex-element" style={{ flexGrow: 1, justifyContent: 'center' }}>
                                <TextBlock icon={resolveIcon(q1?.ListIcon)} title={decode(q1?.Title)} content={decode(q1?.Description)} />
                            </div>

                            <div className="flex-element-end flex-left">
                                <div className="q-button">
                                    <QuestionnaireButton binding="Questionnaire_AmIAtRisk" text={decode(q1?.Title)} />
                                </div>
                            </div>
                        </div>
                        <div className="flex-element col-third" style={{ flexDirection: 'column' }}>
                            <div className="flex-element" style={{ flexGrow: 1, justifyContent: 'center' }}>
                                <TextBlock icon={resolveIcon(q2?.ListIcon)} title={decode(q2?.Title)} content={decode(q2?.Description)} />
                            </div>
                            <div className="flex-element-end flex-left">
                                <div className="q-button">
                                    <QuestionnaireButton binding="Questionnaire_FriendFamilyAtRisk" text={decode(q2?.Title)} />
                                </div>
                            </div>
                        </div>
                        <div className="flex-element col-third" style={{ flexDirection: 'column' }}>
                            <div className="flex-element" style={{ flexGrow: 1, justifyContent: 'center' }}>
                                <TextBlock icon={resolveIcon(q3?.ListIcon)} title={decode(q3?.Title)} content={decode(decode(q3?.Description))} />
                            </div>
                            <div className="flex-element-end flex-left">
                                <div className="q-button">
                                    <QuestionnaireButton binding="Questionnaire_DispellingMyths" text={decode(q3?.Title)} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </PagePanel>
            <PagePanel className="journal-panel">
                <div className="center-element">
                    <a id="journal"></a>
                    <h3>{headJournal}</h3>
                    <p className="left-element" dangerouslySetInnerHTML={{ __html: textJournal }}></p>
                </div>
                <BoundButton
                    className="journal-button"
                    onClick={handleJournalClick}
                    type="primary"
                    binding="Web_Button_WriteJournal.Text"
                    tooltipBinding="Web_Tooltip_WriteJournal.Text"
                />
            </PagePanel>
            {showQuestionnaire && questionnaire === 'Questionnaire_AmIAtRisk' && <BoundQuestionnaire binding={questionnaire} />}
            {showQuestionnaire && questionnaire === 'Questionnaire_FriendFamilyAtRisk' && <BoundQuestionnaire binding={questionnaire} />}
            {showQuestionnaire && questionnaire === 'Questionnaire_DispellingMyths' && <BoundQuestionnaire binding={questionnaire} />}
            {showJournal && <JournalForm />}
        </div>
    );
};

export default IsItAbusePage;
