import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { decode } from 'html-entities';
import { useCountryTranslation } from 'brightsky-3';
import { faHeartBroken, faInfoSquare, faQuestion, faUserHeadset } from '@fortawesome/pro-regular-svg-icons';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import './css/quicklinks.less';
import { BackTop } from 'antd';

const Quicklinks = () => {
    const c = useActiveCountry();
    const { l, ct } = useCountryTranslation(c);

    const b = b => decode(ct(b));

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <div className="quicklinks">
            <h3>{b('Page_Resources.Title')}</h3>
            <div className="link-container">
                <FontAwesomeIcon icon={faInfoSquare} color="#FFFFFF" />
                <Link className="link" title={b('Nav_Home_AbuseAdvice.Text')} to={`/${l}/abuse-advice`} onClick={scrollToTop}>
                    {b('Nav_Home_AbuseAdvice.Text')}
                </Link>
            </div>
            <div className="link-container">
                <FontAwesomeIcon icon={faUserHeadset} color="#FFFFFF" />
                <Link className="link" title={b('Page_Helplines.Title')} to={`/${l}/national-helplines`}>
                    {b('Page_Helplines.Title')}
                </Link>
            </div>
            <div className="link-container">
                <FontAwesomeIcon icon={faQuestion} color="#FFFFFF" />
                <Link className="link" title={b('Nav_Home_IsItAbuse.Text')} to={`/${l}/is-it-abuse`} onClick={scrollToTop}>
                    {b('Nav_Home_IsItAbuse.Text')}
                </Link>
            </div>
            <div className="link-container">
                <FontAwesomeIcon icon={faHeartBroken} color="#FFFFFF" />
                <Link className="link" title={b('Nav_AbuseAdvice_Leaving.Text')} to={`/${l}/abuse-advice#leaving`} onClick={scrollToTop}>
                    {b('Nav_AbuseAdvice_Leaving.Text')}
                </Link>
            </div>
        </div>
    );
};

export default Quicklinks;
