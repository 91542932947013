import { Events, Origins, Templates, useCountryTranslation } from 'brightsky-3';
import { decode } from 'html-entities';
import { Form, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import PagePanel from '../shared/PagePanel';
import './css/feedbackpage.less';
import LocateSupportBanner from '../locatesupport/LocateSupportBanner';
import DefaultSelect from '../shared/DefaultSelect';
import useFeedback from './hooks/useFeedback';
import BoundButton from '../shared/BoundButton';
import useDocumentTitleContext from '../shared/hooks/useDocumentTitleContext';

const FeedbackPage = () => {
    const { values, lists, submit, loading, error, handleEaseOfUse, handleUseAgain, handleHelpful, success, setSuccess } = useFeedback();
    const { setDocumentTitle } = useDocumentTitleContext();

    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);
    const pageTitle = decode(ct('Page_Web_Feedback.Title'));
    const feedbackAdditional = decode(ct('Page_Web_Feedback.AdditionalContent'));
    const feedbackText = decode(ct('Page_Web_Feedback.Content'));
    const ageRangeTitle = decode(ct('Label_ChooseAgeRange.Text'));
    const genderTitle = decode(ct('Label_ChooseGender.Text'));
    const ethnicityTitle = decode(ct('Label_ChooseEthnicity.Text'));
    const feedbackHelpful = decode(ct('Text_Feedback_Helpful.Text'));
    const feedbackEaseOfUse = decode(ct('Text_Feedback_EaseOfUse.Text'));
    const feedbackUseAgain = decode(ct('Text_Feedback_UseAgain.Text'));
    const [submitted, setSubmitted] = useState(false);

    const [form] = Form.useForm();

    const haveEthnicityGeneral = lists?.ethnicity?.general?.length > 0;
    const haveEthnicitySpecific = lists?.ethnicity?.specific?.length > 0;
    const haveAges = lists?.ages?.length > 0;
    const haveGenders = lists?.genders?.length > 0;

    useEffect(() => {
        setDocumentTitle('Feedback. Tell us more: Bright Sky');
    }, [setDocumentTitle]);

    const handleSubmit = () => {
        submit(() => {
            setSubmitted(true);
            setTimeout(() => {
                setSuccess('');
            }, 5000);
        });
    };

    const handleStartOver = () => {
        form.resetFields();
    };
    return (
        <div className="page-isitabuse">
            <LocateSupportBanner />
            <PagePanel id="skip-to" background="#FFFFFF">
                <div className="page-title">
                    <a id="forms-of-abuse" />
                    <h3>{pageTitle}</h3>
                </div>
            </PagePanel>
            <PagePanel>
                <div>
                    <p
                        style={{ marginLeft: '20px', marginRight: '20px' }}
                        className="page-text"
                        dangerouslySetInnerHTML={{ __html: feedbackText }}
                    />
                </div>
                <div position="center" style={{ marginTop: '20px', borderTop: '.5px solid lightGrey' }} />
            </PagePanel>
            <PagePanel>
                <Form style={{ marginLeft: '20px', marginRight: '20px' }} form={form} layout="vertical" onFinish={handleSubmit}>
                    <div>
                        <div>
                            <p>{feedbackHelpful}</p>
                        </div>
                        <div>
                            <Radio.Group
                                defaultValue=""
                                name="helpful"
                                style={{ marginBottom: '40px' }}
                                options={values.helpful.value}
                                onChange={handleHelpful}
                            />
                        </div>
                        <div>
                            <div>
                                <p>{feedbackEaseOfUse}</p>
                            </div>
                            <div>
                                <Radio.Group
                                    defaultValue=""
                                    name="selectEaseOfUse"
                                    style={{ marginBottom: '40px' }}
                                    options={values.easeOfUse.value}
                                    onChange={handleEaseOfUse}
                                />
                            </div>

                            <div>
                                <div>
                                    <p>{feedbackUseAgain}</p>
                                </div>
                                <div>
                                    <Radio.Group
                                        defaultValue=""
                                        name="useAgain"
                                        style={{ marginBottom: '40px' }}
                                        options={values.useAgain.value}
                                        onChange={handleUseAgain}
                                    />
                                </div>
                            </div>
                        </div>
                        <div position="center" style={{ marginTop: '20px', borderTop: '.5px solid lightGrey' }} />
                    </div>
                    <br />
                    {haveGenders && (
                        <Form.Item label={genderTitle} name="gender">
                            <DefaultSelect id="gender" size="medium" items={lists.genders} onChange={values.gender.set} />
                        </Form.Item>
                    )}
                    {haveAges && (
                        <Form.Item label={ageRangeTitle} name="age">
                            <DefaultSelect id="age" size="medium" items={lists.ages} onChange={values.age.set} />
                        </Form.Item>
                    )}
                    {haveEthnicityGeneral && (
                        <>
                            <Form.Item label={ethnicityTitle} name="ethnicity">
                                <DefaultSelect id="ethnicity" size="medium" items={lists.ethnicity.general} onChange={values.ethnicity_general.set} />
                            </Form.Item>
                            {haveEthnicitySpecific && (
                                <Form.Item name="specific">
                                    <DefaultSelect
                                        id="specific"
                                        size="medium"
                                        items={lists.ethnicity.specific}
                                        value={values.ethnicity_specific.value}
                                        onChange={values.ethnicity_specific.set}
                                    />
                                </Form.Item>
                            )}
                        </>
                    )}
                    <div>
                        <p dangerouslySetInnerHTML={{ __html: feedbackAdditional }} />
                    </div>
                    <Form.Item>
                    {success != null && <h3 dangerouslySetInnerHTML={{ __html: ct('Success_SubmittedFeedback.Text')}}/>}
                        {error != null && <div style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: ct('Error_FeedbackFailed.Text')}}/>}
                        <BoundButton
                            className="submit-button"
                            id="submit-button"
                            type="primary"
                            binding="Button_SubmitFeedback.Text"
                            tooltipBinding="Web_Tooltip_SubmitEntry.Text"
                            htmlType="submit"
                            disabled={loading}
                            onClick={handleStartOver}
                        />
                    </Form.Item>
                </Form>
            </PagePanel>
        </div>
    );
};

export default FeedbackPage;
