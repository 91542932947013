export const GuideKeys = {
    TypesOfSupportKey: 'TypesOfSupport',
    LeavingKey: 'Leaving',
    HowCanIHelpKey: 'HowCanIHelp',
    OnlineSafetyKey: 'OnlineSafety',
    SexualConsentKey: 'SexualConsent',
    StalkingHarassmentKey: 'StalkingHarassment',
    FormsOfAbuse: 'FormsOfAbuse',
    AdditionalKey: 'Additional',
};

export const buildGuideName = (name, c) => `${c}_Guide_${name}`;
