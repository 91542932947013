import { useContext } from 'react';
import { LocationSearchContext } from '../contexts/LocationSearchContext';

const useLocationSearchContext = () => {
    const context = useContext(LocationSearchContext);
    if (!context) {
        throw new Error('useLocationSearchContext must be used within a SearchContextProvider');
    }
    return context;
};

export default useLocationSearchContext;
