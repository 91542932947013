import React, { useState } from 'react';
import { useCountryTranslation } from 'brightsky-3';
import useActiveCountry from './hooks/useActiveCountry';
import { decode } from 'html-entities';
import { faPlus, faMinus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './css/boundbutton.less';
import '../isitabuse/css/isitabuse.less';

const ShowMoreShowLess = ({ children, limit, binding1, binding2 }) => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);
    const showMore = decode(ct(binding1));
    const showLess = decode(ct(binding2));
    const text = children;
    const [isShowMore, setShowMore] = useState(false);
    const toggleLink = () => {
        setShowMore(prevState => !prevState);
    };

    return (
        <span>
            <button className="ant-btn ant-btn-text bound-button link-button" onClick={toggleLink}>
                {isShowMore ? (
                    <>
                        <FontAwesomeIcon icon={faMinus} /> {showLess}{' '}
                    </>
                ) : (
                    <>
                        <FontAwesomeIcon icon={faPlus} /> {showMore}{' '}
                    </>
                )}
            </button>
            {isShowMore ? text : text.toString().substring(0, limit)}
        </span>
    );
};

export default ShowMoreShowLess;
