import PropTypes from 'prop-types';
import React from 'react';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { decode } from 'html-entities';
import { animateScroll } from 'react-scroll';
import useQuestionnaireContext from './hooks/useQuestionnaireContext';
import BoundButton from '../shared/BoundButton';
import './css/boundquestionnaire.less';

const PossibleAnswers = ({ qIndex, possibleAnswers }) => {
    const { recordAnswer, answerNames, answers, currentQuestionnaire } = useQuestionnaireContext();
    return possibleAnswers != null
        ? possibleAnswers.map((a, i) => {
              let icon = null;
              if (decode(currentQuestionnaire?.Order) == 3) {
                  if (answers[qIndex] == 2 && answerNames[qIndex] == a.Title) {
                      icon = faCheckCircle;
                  } else if (answers[qIndex] == 1 && answerNames[qIndex] == a.Title) {
                      icon = faTimesCircle;
                  }
              } else {
                  if (answerNames[qIndex] == a.Title) {
                      icon = faCheckCircle;
                  }
              }
              const saveAnswer = () => {
                  recordAnswer(qIndex, a.Value, a.Title);
                  animateScroll.scrollMore(155);
              };
              return (
                  <BoundButton
                      className="answers-grid bound-button"
                      key={i.toString()}
                      icon={icon}
                      text={decode(a.Title)}
                      type="primary"
                      onClick={saveAnswer}
                  />
              );
          })
        : [];
};

PossibleAnswers.propTypes = {
    binding: PropTypes.number,
    possibleAnswers: PropTypes.array,
};

export default PossibleAnswers;
