import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { StorageKeys } from 'brightsky-3';
import { decode } from 'html-entities';
import './css/boundtiles.less';
import useContentContext from './hooks/useContentContext';
import useConfigContext from './hooks/useConfigContext';
import ShowMoreShowLess from './ShowMoreShowLess';

const BoundGuideTiles = ({ binding }) => {
    const { language } = useConfigContext();
    const { content, getContent, loaded } = useContentContext();
    const [items, setItems] = useState([]);
    const [title, setTitle] = useState([]);
    const [description, setDescription] = useState([]);
    const [showLearnMore, setShowLearnMore] = useState(false);

    useEffect(() => {
        const hasContent = content != null && content[StorageKeys.GuideKey] != null;
        if (hasContent) {
            const guideContent = getContent(StorageKeys.GuideKey, binding);
            const innerContent = guideContent?.Guides ? guideContent?.Guides : [];
            setItems(innerContent);
            setTitle(decode(guideContent?.Title));
            setDescription(decode(guideContent?.Description));
            setShowLearnMore(decode(guideContent?.LearnMore));
        }
    }, [content, loaded, setItems, language]);

    const mappedItems =
        items && items.length > 0
            ? items.map((v, i) => {
                  const hasLearnMore = v?.LearnMore != null && v?.LearnMore !== '';
                  const learnMoreContent = <p dangerouslySetInnerHTML={{ __html: decode(v.LearnMore) }}></p>;
                  return (
                      <Card key={i.toString()} title={decode(v.Title)}>
                          <p dangerouslySetInnerHTML={{ __html: decode(v.Content) }}></p>
                          {hasLearnMore && (
                              <span>
                                  <ShowMoreShowLess binding1={'Text_ShowMore.Text'} binding2={'Text_ShowLess.Text'} limit={0}>
                                      {learnMoreContent}
                                  </ShowMoreShowLess>

                                  {showLearnMore}
                              </span>
                          )}
                      </Card>
                  );
              })
            : null;

    return (
        <div>
            <div className="page-title">
                <h3>{title}</h3>
                <p dangerouslySetInnerHTML={{ __html: description }}></p>
                <br />
            </div>
            <div className="flex-element guide-tiles">{mappedItems}</div>
        </div>
    );
};

BoundGuideTiles.propTypes = {
    binding: PropTypes.string.isRequired,
};

export default BoundGuideTiles;
