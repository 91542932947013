import React from 'react';
import PagePanel from '../shared/PagePanel';
import LocateSupportSearch from './LocateSupportSearch';
import useHeroImage from '../shared/hooks/useHeroImage';

const LocateSupportBanner = () => {
    const heroImage = useHeroImage();

    return (
        <PagePanel id="top-of-page" background={`url(${heroImage})`}>
            <div style={{ marginLeft: 24, marginRight: 24 }}>
                <LocateSupportSearch />
            </div>
        </PagePanel>
    );
};

export default LocateSupportBanner;
