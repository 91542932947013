import { useContext } from 'react';
import { DocumentTitleContext } from '../contexts/DocumentTitleContext';

const useDocumentTitleContext = () => {
    const context = useContext(DocumentTitleContext);
    if (!context) {
        throw new Error('useDocumentTitleContext must be used within a DocumentTitleContextProvider');
    }
    return context;
};

export default useDocumentTitleContext;
