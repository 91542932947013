import React, { useState } from 'react';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faMapMarker, faHeadset } from '@fortawesome/pro-solid-svg-icons';
import { decode } from 'html-entities';
import { useCountryTranslation } from 'brightsky-3';
import { useHistory } from 'react-router-dom';
import DefaultTextbox from '../shared/DefaultTextbox';
import BoundButton from '../shared/BoundButton';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import './css/locatesupportsearch.less';
import useAppSettingsContext from '../shared/hooks/useAppSettingsContext';

const LocateSupportSearch = () => {
    const c = useActiveCountry();
    const { ct, l } = useCountryTranslation(c);
    const [location, setLocation] = useState();
    const history = useHistory();
    const { appSettings } = useAppSettingsContext();

    const tbPlaceholder = decode(ct('Label_EnterLocation.Text'));
    const headLocate = decode(ct('Page_Web_LocateSupport.Title'));
    const textLocate = decode(ct('Page_Web_LocateSupport.Content'));
    const hasLocationValue = location != null;

    const onLocationChange = v => {
        setLocation(v.target.value);
    };

    const onSearch = () => {
        history.push(`/${l}/locate-support/${location}`);
    };

    const onNationalResourcesClick = () => {
        history.push(`/${l}/national-helplines`);
    };

    return (
        <div className="locate-support-search">
            <h4>{headLocate}</h4>
            <p dangerouslySetInnerHTML={{ __html: textLocate }} />
            {appSettings?.findHelpSearchEnabled && (
                <div className="locate-support">
                    <div className="search-box">
                        <DefaultTextbox icon={faSearch} placeholder={tbPlaceholder} onChange={onLocationChange} />
                    </div>

                    <div className="search-button">
                        <BoundButton
                            className="locate-button"
                            type="primary"
                            onClick={onSearch}
                            disabled={!hasLocationValue}
                            icon={faMapMarker}
                            binding="Button_LocateSupport.Text"
                            tooltipBinding="Web_Tooltip_LocateSupport.Text"
                        />
                    </div>
                </div>
            )}
            <div className="national-helplines-button secondary">
                <BoundButton
                    className="helpline-button"
                    style={{ position: 'inherit' }}
                    icon={faHeadset}
                    onClick={onNationalResourcesClick}
                    type="secondary"
                    binding="Nav_AbuseAdvice_Helplines.Text"
                    tooltipBinding="Web_Tooltip_NationalHelplines.Text"
                />
            </div>
        </div>
    );
};

export default LocateSupportSearch;
