import React from 'react';
import PropTypes from 'prop-types';
import { faPhone, faLink, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { Card } from 'antd';
import { useCountryTranslation } from 'brightsky-3';
import { Events, Origins, Templates } from 'brightsky-3/constants/Logging';
import { decode } from 'html-entities';
import BoundButton from '../shared/BoundButton';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import IconTag from './IconTag';
import BoxTag from './BoxTag';
import { resolveIcon } from '../../util/functions';
import './css/locationresult.less';
import useWebAnalyticsContext from '../shared/hooks/useWebAnalyticsContext';

const LocationResult = ({ result, number, tagsEnabled = true }) => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);
    const { logEvent } = useWebAnalyticsContext();

    const bind = b => decode(ct(b));
    const locationTags = result?.tags?.filter(t => t?.type === 'Location');
    const supportTags = result?.tags?.filter(t => t?.type === 'Type of Support');

    const handleCall = () => {
        if (Events?.LocationWebsiteEvent) {
            logEvent(
                Events.LocationWebsiteEvent,
                Origins.LocationResults,
                Templates.location.link(result.name, result.website, result.isNationalHelpLine)
            );
        }
        window.open(`tel:${result.phone}`, '_blank');
    };

    const handleEmail = () => {
        if (Events?.LocationEmailEvent) {
            logEvent(Events.LocationEmailEvent, Origins.LocationResults, Templates.location.email(result.name, result.email, result.isNationalHelpLine));
        }
        window.open(`mailto:${result.email}`, '_blank');
    };

    const handleOpenWebsite = () => {
        if (Events?.LocationCallEvent) {
            logEvent(Events.LocationCallEvent, Origins.LocationResults, Templates.location.call(result.name, result.phone, result.isNationalHelpLine));
        }
        window.open(result.website, '_self');
    };

    const supportTagComponents =
        supportTags?.length > 0 ? supportTags.map((t, i) => <BoxTag key={i} text={t.title} color={t.color} borderColor={t.borderColor} />) : <></>;

    const locationTagComponents =
        locationTags?.length > 0 ? locationTags.map((t, i) => <IconTag key={i} icon={resolveIcon(t.icon)} text={t.title} />) : <></>;

    const numbers = number == undefined ? '' : number + '.';

    return (
        <div className="map-result">
            <Card id="skip-to" title={`${numbers} ` + `${result.name}`}>
                <div className="flex-element">
                    <div className="left">
                        {tagsEnabled && (
                            <>
                                <div className="attribute">
                                    <div className="label">{bind('Label_Support.Text')}</div>
                                    <div className="content">{supportTagComponents}</div>
                                </div>
                                <div className="attribute">
                                    <div className="label" dangerouslySetInnerHTML={{ __html: bind('Label_Tags.Text') }}></div>
                                    <div className="content">{locationTagComponents}</div>
                                </div>
                            </>
                        )}
                        {result.hours != null && result.hours != '' && (
                            <div className="attribute">
                                <div className="label" dangerouslySetInnerHTML={{ __html: bind('Label_Hours.Text') }}></div>
                                <div className="content" dangerouslySetInnerHTML={{ __html: result.hours }}></div>
                            </div>
                        )}
                        {result.phone != null && result.phone != '' && (
                            <div className="attribute">
                                <div className="label" dangerouslySetInnerHTML={{ __html: bind('Label_Phone.Text') }}></div>
                                <div className="content">{result.phone}</div>
                            </div>
                        )}
                        {result.website != null && result.website != '' && (
                            <div className="attribute">
                                <div className="label" dangerouslySetInnerHTML={{ __html: bind('Label_Website.Text') }}></div>
                                <div className="content" dangerouslySetInnerHTML={{ __html: result.website }}></div>
                            </div>
                        )}
                        {result.address != null && result.address != '' && (
                            <div className="attribute">
                                <div className="label" dangerouslySetInnerHTML={{ __html: bind('Label_Address.Text') }}></div>
                                <div className="content" dangerouslySetInnerHTML={{ __html: result.address }}></div>
                            </div>
                        )}
                        {result.email != null && result.email != '' && (
                            <div className="attribute">
                                <div className="label">{bind('Label_Email.Text')}</div>
                                <div className="content">{result.email}</div>
                            </div>
                        )}
                        {result.description != null && result.description != '' && (
                            <div className="attribute">
                                <div className="label" dangerouslySetInnerHTML={{ __html: bind('Label_Description.Text') }}></div>
                                <div className="content" dangerouslySetInnerHTML={{ __html: result.description }}></div>
                            </div>
                        )}
                    </div>
                    <div className="right">
                        {result.phone != null && result.phone != '' && (
                            <BoundButton
                                ariaLabel={bind('Button_Call.Text') + ' ' + result.name}
                                onClick={handleCall}
                                className="btn"
                                type="primary"
                                icon={faPhone}
                                binding="Button_Call.Text"
                            />
                        )}
                        {result.website != null && result.website != '' && (
                            <BoundButton
                                ariaLabel={result.name + ' ' + bind('Button_Website.Text')}
                                onClick={handleOpenWebsite}
                                className="btn"
                                type="primary"
                                icon={faLink}
                                binding="Button_Website.Text"
                            />
                        )}
                        {result.email != null && result.email != '' && (
                            <BoundButton
                                ariaLabel={result.name + ' ' + bind('Button_Email.Text')}
                                onClick={handleEmail}
                                className="btn"
                                type="primary"
                                icon={faEnvelope}
                                binding="Button_Email.Text"
                            />
                        )}
                    </div>
                </div>
            </Card>
        </div>
    );
};

LocationResult.propTypes = {
    result: PropTypes.object,
    tagsEnabled: PropTypes.bool,
};

export default LocationResult;
