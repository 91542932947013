import { Events, Origins, Templates } from 'brightsky-3/constants/Logging';
import { decode } from 'html-entities';
import React, { useEffect, useState } from 'react';
import LocateSupportBanner from '../locatesupport/LocateSupportBanner';
import BoundGuideTiles from '../shared/BoundGuideTiles';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import useContentContext from '../shared/hooks/useContentContext';
import useConfigContext from '../shared/hooks/useConfigContext';
import useDocumentTitleContext from '../shared/hooks/useDocumentTitleContext';
import PagePanel from '../shared/PagePanel';
import useWebAnalyticsContext from './../shared/hooks/useWebAnalyticsContext';
import { GuideKeys, buildGuideName } from '../shared/Guides';
import { StorageKeys } from '../../config/Storage';

const EducationResourcesPage = () => {
    const c = useActiveCountry();
    const { language } = useConfigContext();
    const { content, getContent } = useContentContext();
    const [resources, setResources] = useState();

    const { setDocumentTitle } = useDocumentTitleContext();

    const analytics = useWebAnalyticsContext();

    useEffect(() => {
        const hasRscContent = content != null && content[StorageKeys.ResourceKey] != null;
        if (hasRscContent) {
            const resourceContent = getContent(StorageKeys.ResourceKey, 'Links_Resources');
            setResources(resourceContent);
        }
    }, [setResources, language]);

    const headResources = decode(resources?.Title);
    const textResources = decode(resources?.Resources);

    useEffect(() => {
        if (Events?.GuideOpen) {
            analytics.logEvent(Events.GuideOpen, Origins.Guide, Templates.guide.open(buildGuideName(GuideKeys.FormsOfAbuse, c)));
        }
    },[]);

    useEffect(() => {
        setDocumentTitle('Forms of Abuse. Short definitions: Bright Sky');
    }, [setDocumentTitle]);

    return (
        <div className="page-isitabuse">
            <LocateSupportBanner />

            <PagePanel id="skip-to" background="#FFFFFF">
                <BoundGuideTiles binding="Guide_FormsOfAbuse" />
            </PagePanel>
            <PagePanel className="resource-links">
                <div className="page-title">
                    <a id="resources" />
                    <h3>{headResources}</h3>
                </div>
                { textResources && (
                    <ul>
                        { textResources.map((resource) => {
                            return (
                                <li>
                                    <a href={resource.ResourceUrl}>{decode(resource.Title)}</a>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </PagePanel>
        </div>
    );
};

export default EducationResourcesPage;
