import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

export const DocumentTitleContext = createContext();

export const DocumentTitleContextProvider = ({ children }) => {
    const [documentTitle, setDocumentTitle] = useState();

    useEffect(() => {
        if (documentTitle) {
            document.title = documentTitle;
        }
    }, [documentTitle]);

    return (
        <DocumentTitleContext.Provider value={{ documentTitle, setDocumentTitle }}>
            {children}
        </DocumentTitleContext.Provider>
    );
};

DocumentTitleContextProvider.propTypes = {
    children: PropTypes.node,
};
