import { useEffect, useState } from 'react';
import useContentContext from '../../shared/hooks/useContentContext';
import { StorageKeys } from 'brightsky-3';

const useTagsContent = () => {
    const { content, loaded } = useContentContext();
    const [tags, setTags] = useState([]);

    useEffect(() => {
        if (loaded && tags.length === 0) {
            const hasTagsContent = content != null && content[StorageKeys.TagsKey] != null;
            if (hasTagsContent) {
                setTags(content[StorageKeys.TagsKey]);
            }
        }
    }, [setTags, content, loaded]);

    return {
        tagsData: tags,
    };
};

export default useTagsContent;
