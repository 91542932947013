import { useContext } from 'react';
import { ConfigContext } from '../contexts/ConfigContext';

const useConfigContext = () => {
    const context = useContext(ConfigContext);
    if (!context) {
        throw new Error('useConfigContext must be used within a ConfigContextProvider');
    }
    return context;
};

export default useConfigContext;
