import React from 'react';
import PropTypes from 'prop-types';

const BoxTag = ({ text, color, borderColor }) => {
    return (
        <div className="box-tag" style={{ borderColor: borderColor, backgroundColor: color }}>
            {text}
        </div>
    );
};

BoxTag.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    borderColor: PropTypes.string,
};

export default BoxTag;
