import React, { useState } from 'react';
import DefaultModal from '../shared/DefaultModal';
import { Col, Form, Row, Spin } from 'antd';
import DefaultSelect from '../shared/DefaultSelect';
import BoundButton from '../shared/BoundButton';
import CountrySelect from '../shared/CountrySelect';
import useConfigContext from '../shared/hooks/useConfigContext';
import { StorageKeys } from 'brightsky-3';
import { Events, Templates } from 'brightsky-3/constants/Logging';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import useConfig from '../../config/hooks/useConfig';
import useWebAnalyticsContext from '../shared/hooks/useWebAnalyticsContext';
import useContentContext from '../shared/hooks/useContentContext';
import useLoader from '../shared/hooks/useLoader';

const ConfigPage = () => {
	const env = useConfig();
    const c = useActiveCountry();

    const { logEvent, consumerId } = useWebAnalyticsContext();
    //const history = useHistory();
    

    const [form] = Form.useForm();
    const [countryRedirect, setRedirectURL] = useState({ isWebRedirect: false, WebRedirectURL: null });
    const config = useConfigContext();
	const content = useContentContext();

	const loader = useLoader();

	// WEB REDIRECT DETAILS
    const getWebRedirectByCountry = (countyCode) => {
        const countryContent = content.getRawContent(StorageKeys.CountryStore);
        const selectedCountry = countryContent.filter(c => c.Code.iv === countyCode);
        if (selectedCountry.length > 0) {
            return {
                isWebRedirect: selectedCountry[0].WebRedirect ? selectedCountry[0].WebRedirect.iv : false,
                WebRedirectURL: selectedCountry[0].RedirectURL ? selectedCountry[0].RedirectURL.iv : null,
            };
        }
        return {};
    };

    // useEffect(() => {
    //     if (appSettings.defaultLanguageCode) {
    //         setLanguage(appSettings.defaultLanguageCode);
    //         form.setFieldsValue({ language: appSettings.defaultLanguageCode });
    //         setHasSelectedLanguage(true);
    //     }
    // }, [appSettings]);

    const modalTitle = 'Website Settings';// : decode(ct('Web_Config_Title_WebsiteSettings.Text'));
    const countrySelectLabel = 'Select Country';// : decode(ct('Web_Label_SelectCountry.Text'));
    const languageSelectLabel = 'Select Language';// : decode(ct('Web_Label_SelectLanguage.Text'));
    const goToWebsiteButton = 'Go To Website';// : decode(ct('Web_Button_GoToWebsite.Text'));

    const onCountrySelect = async (country) => {

		config.setLanguage();
		form.setFieldsValue({ language: null});

		const valid_langs = loader.getLanguagesByCountry(
			content.getRawContent(StorageKeys.CountryStore), 
			country
		);
		config.setLanguages(valid_langs);//set default lang, then do everything else

		loader.enableTheme(config.themes, country);//might happen before themes finish loading, but we dont care because form submission results in page reload

		const [p_app_settings, p_content_store] = await Promise.allSettled([
			loader.fetchAppSettings(country), 
			loader.fetchCountrySpecificContent(content.content, country),
		]);
		const app_settings = p_app_settings.value;
		const content_store = p_content_store.value;
		
		config.setLanguage(app_settings.defaultLanguageCode);
		form.setFieldsValue({ language: app_settings.defaultLanguageCode});
		//await loader.setTranslations(country, app_settings.defaultLanguageCode);
		config.setCountry(country);

        const webRedirect = getWebRedirectByCountry(country);
        setRedirectURL(webRedirect);

        if (Events?.SettingsChange) {
            logEvent(Events.SettingsChange, 'ConfigScreen_Web', Templates.settings.change('country', country));
        }
    };

    const onLanguageSelect = async (l) => {
        config.setLanguage(l);
		//await loader.setTranslations(config.country, appSettings.defaultLanguageCode);

        if (Events?.SettingsChange) {
            logEvent(Events.SettingsChange, 'ConfigScreen_Web', Templates.settings.change('language', l));
        }
    };

    const onConfirm = () => {
        if (countryRedirect.isWebRedirect) {
            window.location.href = countryRedirect.WebRedirectURL;
        } else {
            if (!env.isLocalhost) {
                const countryUrl = env.baseCountryUri.replace('{c}', config.country.toLowerCase()).replace('{l}', config.language);
                window.location.href = `${countryUrl}/${consumerId}`;
            }
        }
    };

    const formValid = !!config.country && (!!config.language || countryRedirect?.isWebRedirect===true);

    const footer = (
        <Row>
            <Col style={{ textAlign: 'right' }} span={24}>
                <BoundButton onClick={onConfirm} text={goToWebsiteButton} disabled={!formValid && !countryRedirect.isWebRedirect} />
            </Col>
        </Row>
    );

    return (
        <div className="config">
            {config.hasConfirmedConfig && <Spin size="large" style={{ marginTop: 50, height: '100vh' }} />}
            {!config.hasConfirmedConfig && (
                <DefaultModal visible title={modalTitle} footer={footer} width={700}>
                    <Form form={form} layout="vertical">
                        <Form.Item label={countrySelectLabel} name="country" required>
                            <CountrySelect size="large" onChange={onCountrySelect} />
                        </Form.Item>
                        {config.languages && config.languages.length > 0 && !(countryRedirect?.isWebRedirect===true) && (
                            <Form.Item label={languageSelectLabel} name="language" required>
                                <DefaultSelect disabled={!config.language} loading={!config.language} size="large" onChange={onLanguageSelect} items={config.languages} />
                            </Form.Item>
                        )}
                    </Form>
                </DefaultModal>
            )}
        </div>
    );
};

export default ConfigPage;
