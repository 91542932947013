import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import './css/defaulttextbox.less';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DefaultTextbox = ({ onChange, onBlur, value, icon, placeholder, id, name, className, disabled = false }) => {
    const iconElement = icon ? <FontAwesomeIcon icon={icon} /> : null;
    return (
        <Input
            style={{ position: 'inherit' }}
            className={`default-textbox ${className ?? ''}`}
            onBlur={onBlur}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            disabled={disabled}
            suffix={iconElement}
            id={id}
            name={name}
        />
    );
};

DefaultTextbox.propTypes = {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    icon: PropTypes.any,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
};

export default DefaultTextbox;
