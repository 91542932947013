import { ContentStorageKeys, StorageKeys, i18nService } from 'brightsky-3';
import i18n from '../../../util/i18n';
import useConfig from '../../../config/hooks/useConfig';
import { ContentStorageKeys as localKeys } from '../../../config/Storage';
import { useHistory } from 'react-router';
import useContentContext from './useContentContext';
import useConfigContext from './useConfigContext';
import useAppSettingsContext from './useAppSettingsContext';

const useLoader = () => {
    const env = useConfig();

    const history = useHistory();

    const content = useContentContext();
    const config = useConfigContext();
    const { setAppSettings, setAppSettingsLoading } = useAppSettingsContext();

    const fetchCountries = async (content_store) => {
        let data = null;
        let result = await fetch(`${env.apiUrl}content/Countries`).then(async (result) => {
            if (result && result.ok) {
                data = await result.json();
            }
        }).catch((err) => {
            console.error('ContentContext: Unable to retrieve Countries', env.apiUrl, err);
        });

        if (data === null) {console.error('useAppSettings: Unable to retrieve Countries', `Status code: ${result?.status} ${result?.statusText}`);}

        const ctx = { ...content_store, [StorageKeys.CountryStore]: data };
        content.setContent(ctx);
        content.setLoaded(true);

        return ctx;
    };

    const fetchCountrySpecificContent = async (content_store, country) => {
        let ctx = {};
        // merge "Resources" from local keys
        var workingStorageKeys = ContentStorageKeys.concat(localKeys);
        let proms = workingStorageKeys.map(async (storageKey) => {
            if (storageKey.name !== 'Countries') {
                let result = await fetch(`${env.apiUrl}content/${storageKey.name}?country=${country}`).catch((err) => {
                    console.error('ContentContext: Unable to retrieve data', storageKey, env.apiUrl, err);
                });

                if (result && result.status === 200) {
                    ctx[storageKey.key] = await result.json();
                } else {
                    console.error('Unable to retrieve country specific content', `Status code: ${result?.status} ${result?.statusText}`);
                }
            }
        });

        await Promise.allSettled(proms);

        ctx = { ...ctx, ...content_store };

        content.setContent(ctx);
        content.setLoaded(true);

        return ctx;
    };

    const getLanguagesByCountry = (country_store, country) => {
        const selectedCountry = country_store.filter(c => c.Code.iv === country);
        if (selectedCountry.length > 0) {
            const mappedLanguages = selectedCountry[0].Languages.iv.map(l => {
                return {
                    value: l.Code,
                    label: l.Language,
                };
            });
            return mappedLanguages;
        }
        return [];
    };

    const fetchThemes = async () => {
        let data = null;
        let result = await fetch(`${env.apiUrl}content/themes`).then(async (result) => {
            if (result.ok) {
                data = await result.json();
            }
        }).catch((err) => {
            console.error('ERROR: Unable to load themes: ', err);
        });
        if (data === null)console.error('ERROR: Unable to load themes', `Status ${result?.status} ${result?.statusText}`);

        const mappedThemes = data !== null ? data.map(t => {
            return {
                country: t.Country.iv,
                primary: t.Primary.iv,
                secondary: t.Secondary.iv,
                text: t.Text.iv,
                headerandlinks: t.HeaderAndLinks.iv,
                hover: t.Hover.iv,
                icons: t.Icons.iv,
                exit: t.Exit.iv,
                page: t.Page.iv,
            };
        }) : null;

        config.setThemes(mappedThemes);

        return mappedThemes;
    };

    const enableTheme = async (themes, country) => {
        const currentTheme = themes?.filter(t => t.country === country)[0];
        if (window.less && currentTheme) {
            config.setTheme(currentTheme);

            if (currentTheme) {
                window.less.modifyVars({
                    '@primary-color': currentTheme.primary,
                    '@secondary-color': currentTheme.secondary,
                    '@text-color': currentTheme.text,
                    '@headerandlinks-color': currentTheme.headerandlinks,
                    '@hover-color': currentTheme.hover,
                    '@icons-color': currentTheme.icons,
                    '@exit-color': currentTheme.exit,
                }).catch(error => {
                    console.error('ERROR: Could not apply theme', error);
                });
            }
        } else {
            console.warn("Could not apply theme at this time.");
        }
    };

    const setTranslations = async (country, lang) => {
        let promise = await new Promise((resolve, reject) => {
            i18nService.LoadWebTranslations(env.apiUrl, country, lang, (translations) => {
                for (const [key, value] of Object.entries(translations)) {
                    i18n.addResourceBundle(key, 'translation', value.translation);
                }
                resolve();

                i18n.changeLanguage(lang);
                document.documentElement.setAttribute('lang', lang);
            });
        });
        return promise;
    };

    const setNullTranslation = async () => {
        i18n.changeLanguage("VF");
        document.documentElement.setAttribute('lang', 'en');
    };

    // const redirectToLang = () => {
    // 	const appSettingsLang = appSettings.appSettings.defaultLanguageCode;
    // 	if (appSettingsLang) {
    // 		window.location.href = `/${appSettingsLang}/home`;
    // 	} else {
    // 		window.history.pushState('', '', '/en/home');
    // 	}
    // };

    const fetchAppSettings = async (countryCode) => {
        setAppSettingsLoading(true);

        let result = await fetch(`${env.apiUrl}content/appsettings?country=${countryCode}`).catch((ex) => {
            console.warn('useAppSettings: Unable to retrieve data', env.apiUrl, ex);
        });

        if (!result || result.status !== 200) throw 'useAppSettings: Unable to retrieve data', `Status code: ${result?.status} ${result?.statusText}`;


        const asContent = await result.json();
        const mappedAppSettings = {
            defaultLanguageCode: asContent?.DefaultLanguageCode?.iv,
            exitUrl: asContent?.WebsiteExitUrl?.iv,
            webHeroImage: asContent?.WebHeroImage?.iv,
            locationFiltering: asContent?.LocationFilteringEnabled?.iv,
            iosInstallUrl: asContent?.IosInstallUrl?.iv,
            androidInstallUrl: asContent?.AndroidInstallUrl?.iv,
            findHelpSearchEnabled: asContent?.FindHelpSearchEnabled?.iv,
            additionalGuideEnabled: asContent?.AdditionalGuideEnabled?.iv,
            newResourcesEnabled: asContent?.NewResourcesEnabled?.iv,
        };
        setAppSettings(mappedAppSettings);
        setAppSettingsLoading(false);

        return mappedAppSettings;
    };

    return { getLanguagesByCountry, fetchThemes, enableTheme, setTranslations, setNullTranslation, /*redirectToLang,*/ fetchAppSettings, fetchCountries, fetchCountrySpecificContent };
};

export default useLoader;
