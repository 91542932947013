import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './css/textblock.less';
import { Link } from 'react-router-dom';
import BoundButton from './BoundButton';
import { Events, Templates } from 'brightsky-3';
import useWebAnalyticsContext from './hooks/useWebAnalyticsContext';

const TextBlock = ({ icon, title, content, link, linkTitle, buttonTitle, actions, guideLink }) => {
    const { logEvent } = useWebAnalyticsContext();

    const onLinkClick = () => {
        if (!guideLink) {
            logEvent(Events.NavigationEvent, window.location.host, Templates.navigation.to(link));
        }
        window.location.href = link;
    };

    return (
        <div id="skip-to" className="text-block">
			{icon &&<div className="icon-column">
				<FontAwesomeIcon icon={icon} />
			</div>}
            <div className="text-column">
                <div className="block-title">{title}</div>
                <div className="block-content" dangerouslySetInnerHTML={{ __html: content }}></div>
                {link && linkTitle && (
                    <div className="block-link-box">
                        <Link className="block-link" to={link}>
                            {linkTitle}
                        </Link>
                        {actions}
                    </div>
                )}
                {link && buttonTitle && (
                    <div className="block-link-box">
                        <BoundButton type="primary" text={buttonTitle} onClick={onLinkClick} />
                        {actions}
                    </div>
                )}
                {!link && !buttonTitle && !linkTitle && <div className="block-link-box">{actions}</div>}
            </div>
        </div>
    );
};

TextBlock.propTypes = {
    icon: PropTypes.any,
    title: PropTypes.string,
    buttonTitle: PropTypes.string,
    linkTitle: PropTypes.string,
    link: PropTypes.string,
    content: PropTypes.any,
    actions: PropTypes.any,
    guideLink: PropTypes.bool,
};

export default TextBlock;
