import { useCountryTranslation } from 'brightsky-3';
import { decode } from 'html-entities';
import React, { useEffect } from 'react';
import LocateSupportBanner from '../locatesupport/LocateSupportBanner';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import useDocumentTitleContext from '../shared/hooks/useDocumentTitleContext';
import PagePanel from '../shared/PagePanel';
import './css/safetyguidancepage.less';

const SafetyGuidancePage = () => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);
    const { setDocumentTitle } = useDocumentTitleContext();

    const pageTitle = decode(ct('Page_Web_SafetyGuidance.Title'));
    const textSafetyGuidance = decode(ct('Page_Web_SafetyGuidance.Content'));

    useEffect(() => {
        setDocumentTitle('Safety Guidance: Bright Sky');
    }, [setDocumentTitle]);

    return (
        <div className="page-safetyguidance">
            <LocateSupportBanner />
            <PagePanel background="#FFFFFF">
                <div className="page-title">
                    <h3>{pageTitle}</h3>
                </div>
            </PagePanel>
            <PagePanel>
                <p style={{ marginLeft: '20px', marginRight: '20px' }} dangerouslySetInnerHTML={{ __html: textSafetyGuidance }} />
            </PagePanel>
        </div>
    );
};

export default SafetyGuidancePage;
