import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { decode } from 'html-entities';
import { StorageKeys, useCountryTranslation } from 'brightsky-3';
import PagePanel from '../shared/PagePanel';
import BoundButton from '../shared/BoundButton';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import useContentContext from '../shared/hooks/useContentContext';
import './css/homepage.less';
import useConfigContext from '../shared/hooks/useConfigContext';

const OnlineSafetyPanel = () => {
    const c = useActiveCountry();
    const { l } = useCountryTranslation(c);
    const history = useHistory();
    const { language } = useConfigContext();
    const { content, getContent } = useContentContext();
    const [onlineSafetyGuide, setOnlineSafetyGuide] = useState();

    useEffect(() => {
        const hasGContent = content != null && content[StorageKeys.GuideKey] != null;
        if (hasGContent) {
            const onlineSafetyContent = getContent(StorageKeys.GuideKey, 'Guide_OnlineSafety');
            setOnlineSafetyGuide(onlineSafetyContent);
        }
    }, [setOnlineSafetyGuide, language]);

    const headOnlineSafety = decode(onlineSafetyGuide?.Title);
    const description = decode(onlineSafetyGuide?.Description);

    return (
        <PagePanel>
            <div className="online-safety-panel">
                <h3>{headOnlineSafety}</h3>
                <div style={{ wordBreak: 'break-word' }}>
                    <p dangerouslySetInnerHTML={{ __html: description }}></p>
                    <br />
                </div>
                <div style={{ textAlign: 'left' }}>
                    <BoundButton
                        className="home-page-buttons"
                        type="primary"
                        binding="Web_Button_GoToOnlineSafety.Text"
                        tooltipBinding="Web_Tooltip_GoToOnlineSafety.Text"
                        onClick={() => {
                            window.scrollTo(0, 0);
                            history.push(`/${l}/online-safety-guide`);
                        }}
                    />
                </div>
            </div>
        </PagePanel>
    );
};

export default OnlineSafetyPanel;
