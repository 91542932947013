import { faArrowCircleUp } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import BoundLinkButton from './BoundLinkButton';
import './css/boundbutton.less';

const BackToTop = () => {
    const handleToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <div style={{ textAlign: 'right' }}>
            <BoundLinkButton ariaLabel="Back To Top Button" icon={faArrowCircleUp} binding="Web_Button_BackToTop.Text" onClick={handleToTop} />
        </div>
    );
};

export default BackToTop;
